<template>
  <footer class="footer">
    <div class="container">
      <div class="custom-columns">
        <div
          v-for="(t, index) in tops"
          :key="t.value"
          class="custom-column"
        >
          <SampleFooterButton 
            :top="t"
            :is-active="t.value == top.value"
            @input="selectTop(index)"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SampleFooterButton from './SampleFooterButton'

export default {
  name: 'SampleFooter',
  components: {
    SampleFooterButton
  },
  inject: ['scrollToTop'],
  computed: {
    ...mapGetters('sample', ['tops', 'top'])
  },
  methods: {
    ...mapActions('sample', ['setTop']),
    selectTop(index) {
      this.setTop(index)
      this.scrollToTop()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

footer.footer {
  position: fixed;
  bottom: $bottom-size;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 10;

  .container {
    padding: 0;
    height: 100%;

    .custom-columns {
      display: flex;
      justify-content: space-around;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 5em;
      margin-right: 5em;

      .custom-column {
        height: 100%;
      }
    }
  }
}
</style>
