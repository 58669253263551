<template>
  <header class="SampleHeader">
    <div class="SampleHeader-checkbox">
      <b-checkbox :value="indiceWeight" @input="$emit('input', $event)" type="is-top150">
        {{ $t('show_raw_values') }}
      </b-checkbox>
    </div>

    <div class="SampleHeader-actions">
      <span
        class="SampleHeader-actions-export"
        @click="exportVehicles"
      >
        {{ $t('export_vehicles') }} <b-icon icon="file-download"></b-icon>
      </span>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/lang'

import api from '@/api/export.js'
import { dataURI_DL } from '@/utils/helpers'

export default {
  name: 'SampleHeader',
  inject: ['scrollToTop'],
  props: {
    indiceWeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPressed: false
    }
  },
  computed: {
    ...mapGetters('sample', ['uuid'])
  },
  methods: {
    exportVehicles() {
      if (this.uuid == null) {
        return false
      }

      this.$buefy.toast.open({
        message: i18n.t('export_vehicles_start'),
        type: 'is-primary',
        queue: false
      })

      api.exportVehicles(this.uuid).then(response => {
        const pre = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"

        dataURI_DL(pre + response.data.file, response.data.fileName)

        this.$buefy.toast.open({
          message: i18n.t('export_vehicles_end'),
          type: 'is-primary',
          queue: false
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: i18n.t('error_export_vehicles'),
          type: 'is-danger',
          queue: false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SampleHeader{
  display: flex;
  align-items: center;

  &-checkbox {
    flex: 1;
  }

  &-actions {
    display: flex;
    align-items: center;

    &-export {
      display: inline-flex;
      align-items: center;
      margin: 0 10px;
      text-decoration: underline;
      cursor: pointer;

      &-icon {
        margin-left: 8px;
      }
    }

    &-button {
      display: flex;
      width: 48px;
      height: 48px;
      font-size: 28px;
      padding: 0.1em;

      &:focus, &:active {
        border: 1px solid rgb(181, 181, 181);
      }
      &:focus:not(:active) {
        box-shadow: none;
      }

      &.is-pressed {
        background-color: rgb(136, 197, 238);
        border: solid rgb(86, 111, 250) 1px;

        &:hover {
          background: rgb(108, 173, 216);
        }
      }
    }
  }
}
</style>
