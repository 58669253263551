import { http } from './http'

const baseUrl = `${http.defaults.baseURL}/filter`

export default {
  getFilterAge(bu) {
    return http.get(`${baseUrl}/age`, { params: { bu } })
  },
  getFilterBody() {
    return http.get(`${baseUrl}/body`)
  },
  getFilterBrand() {
    return http.get(`${baseUrl}/brand`)
  },
  getFilterCluster(bu) {
    return http.get(`${baseUrl}/cluster`, { params: { bu } })
  },
  getFilterCountry(buisness_brand) {
    return http.get(`${baseUrl}/country`, { params: { buisness_brand } })
  },
  getFilterCv() {
    return http.get(`${baseUrl}/cv`)
  },
  getFilterEnergy() {
    return http.get(`${baseUrl}/energy`)
  },
  getFilterMoney() {
    return http.get(`${baseUrl}/money`)
  },
  getFilterTop(bu) {
    return http.get(`${baseUrl}/top`, { params: { bu } })
  },
  getFilterUniverse(bu) {
    return http.get(`${baseUrl}/universe`, { params: { bu } })
  },
}
