import axios from 'axios'

let instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND
})

instance.interceptors.request.use((config) => {
  const tokenKey = sessionStorage.getItem('vp_token')
  if (tokenKey) {
    config.headers.Authorization = JSON.parse(tokenKey).jwtToken
  }
  return config
})

export const http = instance