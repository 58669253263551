import Vue from 'vue'
import Notification from './Notification'

const NotificationProgrammatic = {
  open(params) {
      let message
      let parent
      if (typeof params === 'string') message = params

      const defaultParam = {
          message
      }
      if (params.parent) {
          parent = params.parent
          delete params.parent
      }
      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const NotificationComponent = vm.extend(Notification)
      
      return new NotificationComponent({
          parent,
          el: document.createElement('div'),
          propsData
      })
  }
}

export {
  NotificationProgrammatic
}