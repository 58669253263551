export default class Vehicle {
  constructor(data) {
    const header = data._source.data.header

    let trendArrow = data.fields.trend_arrows[0]

    let weight = Math.round((100 / header.prc1m_proportion_top150e * header.prc1m_proportion) * 10) / 10
    let proportion = Math.round(header.prc1m_proportion) / 1000
    let brutWeight = Math.round(header.ktypnr_cnt_bu_year * header.prc1m_proportion / 1000000) + ''

    let brutWeight_len = brutWeight.length
    brutWeight = brutWeight.split('', 2).join('')

    switch (brutWeight_len) {
      case 6:
        brutWeight += '0K';
        break;
      case 5:
        brutWeight += 'K';
        break;
      case 4:
        brutWeight += '00';
        break;
      case 3:
        brutWeight += '0';
        break;
    }

    let breakpoint = parseInt(process.env.VUE_APP_VEHICLE_BREAKPOINT)
    for (let i = breakpoint; i <= 1000000; i += breakpoint) {
      if (header.break.break_.from_ <= i && header.break.break_.to_ >= i) {
        this.percentBreak = i / 10000
      }
    }

    this.ktype = header.vehicle.ktypnr
    this.brand = header.vehicle.brand
    this.model = header.vehicle.model
    this.version = header.vehicle.version
    this.rank = header.rk
    this.trend = trendArrow
    this.weight = weight
    this.brutWeight = brutWeight
    this.proportion = proportion
    this.currentTag = 0
    this.infos = {
      year: header.vehicle.year,
      kw: header.vehicle.kw,
      body: header.vehicle.body,
      energy: header.vehicle.energy
    }
  }
}