<template>
  <div class="Item">
    <span v-if="item.color" class="Item-token" :style="`background-color:${item.color}`"></span>

    <span class="Item-name">
      <b-tooltip
        v-if="item.tooltip"
        :label="item.tooltip"
        class="monofont"
      >{{ item.name }}</b-tooltip>
      <template v-else>{{ item.name }}</template>
    </span>
      
    <a
      v-if="item.uniqueLink"
      :href="item.link"
      target="_blank"
    >{{ $t('vehicles_distribution') }}</a>

    <span class="Item-nb">
      {{ item.ktypnr_nb }}
      <b-icon icon="car"></b-icon>
    </span>

    <b-progress
      :show-value="false"
      :value="item.immat_volume_ratio"
      type="is-primary"
      class="Item-progress"
    ></b-progress>
    
    <div class="Item-stats">
      <div class="Item-stats-main">
        {{ getVolume }}
      </div>
      <div class="Item-stats-small">
        {{ getVolumeRatio }}‰
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    item: {}
  },
  computed: {
    getVolume() {
      let volume = this.item.immat_volume
      let volume_len = volume.toString().length

      volume = volume.toString()

      if (volume_len >= 5) {
        volume = volume.split('', volume_len - 3).join('') + 'K'
      }

      return volume
    },
    getVolumeRatio() {
      return parseFloat((this.item.immat_volume_ratio * 10).toFixed(2))
    }
  }
}
</script>

<style lang="scss" scoped>
.Item {
  display: flex;
  align-items: center;

  &-name {
    flex: 1;
  }

  &-token {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 4px;
    border-radius: 50%;
  }

  &-nb {
    min-width: 100px;
    text-align: right;
    font-weight: bold;
  }

  &-progress {
    width: 180px;
    margin: 0 8px !important;
  }

  &-stats {
    width: 90px;
    text-align: right;

    &-main {
      font-size: 20px;
      font-weight: bold;
      line-height: 16px;
    }

    &-small {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
</style>