import { getValidPark } from '@/utils/constructFilters'

export default class Cluster {
  constructor(data, park) {
    if (park === undefined) {
      this._constructFilter(data)
    } else {
      this._constructFilterByPark(data, park)
    }
  }

  _constructFilter(data) {
    const choices = data[0].data.body.choices

    choices.map(choice => {
      choice.name = choice.cluster_cat
      choice.color = choice._source[0]
    })

    let allChoicesHaveSameLink = false
    let previousLink = choices[0]
    choices.forEach(choice => {
      if (choice.link !== previousLink) {
        allChoicesHaveSameLink = true
      }
      previousLink = choice.link
    })

    if (allChoicesHaveSameLink) {
      this.link = previousLink
    } else {
      choices.map(choice => {
        choice.uniqueLink = true
      })
    }

    this.header = data[0].data.header
    this.section = this.header.filter
    this.needUniqueBu = true
    this.possiblevalues = {
      choices,
      fastCheck: []
    }
  }

  _constructFilterByPark(filter, park) {
    const choices = park.possiblevalues

    choices.map(choice => {
      choice.name = choice.cluster_cat
      choice.color = choice._source[0]
    })

    let allChoicesHaveSameLink = false
    let previousLink = choices[0]
    choices.forEach(choice => {
      if (choice.link !== previousLink) {
        allChoicesHaveSameLink = true
      }
      previousLink = choice.link
    })

    if (allChoicesHaveSameLink) {
      this.link = previousLink
    } else {
      choices.map(choice => {
        choice.uniqueLink = true
      })
    }

    const possiblevalues = {
      choices: choices,
      fastCheck: []
    }
    const selectedvalues = possiblevalues.choices.filter(choice => choice.isChecked || choice.isDisabled)

    this.header = filter.header
    this.section = this.header.filter
    this.needUniqueBu = true
    this.possiblevalues = possiblevalues
    this.selectedvalues = selectedvalues

    if (filter.header.version !== park.version) {
      const validPark = getValidPark(filter, park, selectedvalues, possiblevalues)

      this.possiblevalues = validPark.possiblevalues
      this.selectedvalues = validPark.selectedvalues
      this.error = validPark.error
      this.messages = validPark.messages
    }
  }
}
