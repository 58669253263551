import Vue from 'vue'

import apiSample from '@/api/sample'

import VehicleModel from '@/models/vehicle'

const ITEMS_PER_PAGE = parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) || 20
const tops = [
  { label: 'Top 150', value: 150, proportion: 25, color: 'is-top150' },
  { label: 'Top 760', value: 760, proportion: 25, color: 'is-top760' },
  { label: 'Top 2k4', value: 2400, proportion: 25, color: 'is-top2400' },
  { label: 'Top 9k6', value: 9600, proportion: 25, color: 'is-top9600' }
]

const state = {
  sample: [],
  tops: tops,
  top: 0,
  mnemonique: null,
  uuid: null,
  total: 0,
  dataStore: []
}

const mutations = {
  FETCH_SAMPLE(state, data) {
    const page = data.page || 1
    const dataExist = state.dataStore.filter(s => {
      return s.idpark === data.idpark && s.top === data.top
    })
    dataExist.length > 0 ? dataExist[0].pages = [page] : state.dataStore.push({
      idpark: data.idpark,
      top: data.top,
      pages: [page]
    })

    let vehicles = []
    let nbVehiclesToInsert = 0
    let vehiclesInserted = false

    for (let i = 0; i < data.total; i++) {
      if ((i / ITEMS_PER_PAGE) + 1 === page) {
        nbVehiclesToInsert = data.vehicles.length
      }

      if (nbVehiclesToInsert > 0) {
        if (!vehiclesInserted) {
          data.vehicles.forEach((v, index) => {
            let vehicle = new VehicleModel(v)
            vehicle.index = i + index
            vehicles.push(vehicle)
          })
          vehiclesInserted = true
        }
        nbVehiclesToInsert--
      } else {
        vehicles.push({ index: i })
      }
    }
    
    state.sample = vehicles
    state.total = data.total
  },
  SCROLL_SAMPLE(state, data) {
    let nbVehiclesToInsert = 0
    let vehiclesInserted = false

    for (let i = 0; i < data.total; i++) {
      if ((i / ITEMS_PER_PAGE) + 1 === data.page) {
        nbVehiclesToInsert = data.vehicles.length
      }

      if (nbVehiclesToInsert > 0) {
        if (!vehiclesInserted) {
          data.vehicles.forEach((v, index) => {
            let vehicle = new VehicleModel(v)
            vehicle.index = i + index
            Vue.set(state.sample, i + index, vehicle)
          })
          vehiclesInserted = true
        }
        nbVehiclesToInsert--
      }
    }
  },
  SET_TOP(state, top) {
    state.top = top
  },
  SET_MNEMONIQUE_UUID(state, { mnemonique, uuid }) {
    state.mnemonique = mnemonique
    state.uuid = uuid
  }
}

const getters = {
  sample: state => state.sample,
  tops: state => state.tops,
  top: state => state.tops[state.top],
  mnemonique: state => state.mnemonique,
  uuid: state => state.uuid,
  total: state => state.total,
  dataStore: state => state.dataStore
}

const actions = {
  fetchSample({ commit, getters, rootGetters }) {
    const idpark = rootGetters['workshop/workshop'].idpark
    if (idpark === -1 || getters.mnemonique == null) {
      return
    }

    const data = {
      mnemonique: getters.mnemonique,
      uuid: getters.uuid,
      top: getters.top.value,
      universe: 'ALL'
    }

    return apiSample.getSample(data).then(response => {
      commit('FETCH_SAMPLE', {
        total: response.data.total,
        vehicles: response.data.datas,
        idpark: idpark,
        top: getters.top.value,
        page: 1
      })
    })
  },
  async scrollSample({ commit, getters, rootGetters, dispatch }, currentPage) {
    const idpark = rootGetters['workshop/workshop'].idpark
    if (idpark === -1 || getters.mnemonique == null) {
      return
    }

    const dataSample = getters.dataStore.filter(s => {
      return s.idpark === idpark && s.top === getters.top.value
    })[0]
    if (dataSample == null) {
      return dispatch('fetchSample')
    }

    const countPages = Math.ceil(getters.total / ITEMS_PER_PAGE)
    let calls = []
    let d = {
      mnemonique: getters.mnemonique,
      uuid: getters.uuid,
      top: getters.top.value,
      universe: 'ALL',
      page: currentPage
    }

    if (!dataSample.pages.includes(currentPage)) {
      calls.push(d)
    }
    if (currentPage - 1 > 0 && !dataSample.pages.includes(currentPage - 1)) {
      calls.push({ ...d, page: currentPage - 1 })
    }
    if (currentPage + 1 <= countPages && !dataSample.pages.includes(currentPage + 1)) {
      calls.push({ ...d, page: currentPage + 1 })
    }
    if (calls.length === 0) {
      return
    }

    const stuffArray = calls.map(d => apiSample.getSample(d))

    calls.forEach(d => {
      dataSample.pages.push(d.page)
    })

    await Promise.all(stuffArray).then((result) => {
      result.forEach((r, index) => {
        commit('SCROLL_SAMPLE', {
          total: r.data.total,
          vehicles: r.data.datas,
          top: getters.top.value,
          page: calls[index].page
        })
      })
    })
  },
  setTop({ commit, dispatch }, top) {
    commit('SET_TOP', top)
    dispatch('fetchSample')
  },
  setMnemoniqueUuid({ commit }, data) {
    commit('SET_MNEMONIQUE_UUID', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}