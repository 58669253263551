<template>
  <div :class="['VehicleItem', { 'has-data': source.ktype != null, 'is-pair': index % 2 == 0 }]">
    <template v-if="source.ktype != null">
      <div class="VehicleItem-wrapper">
        <div class="VehicleItem-wrapper-tag" @click="toggleTag">
          <b-icon
            :icon="tags[source.currentTag].icon"
            :pack="tags[source.currentTag].pack"
            :style="{ color: tags[source.currentTag].color }"
          ></b-icon>
        </div>
        <div class="VehicleItem-wrapper-details" @click="vehicleInfos">
          <b-icon icon="info-circle"></b-icon>
        </div>
        <div>
          #{{ source.rank }} -
        </div>
        <div class="VehicleItem-wrapper-logo">
          <img
            :src="getBrandImgUrl(source.brand)"
            :alt="source.brand"
          />
        </div>
        <div class="VehicleItem-wrapper-name">
          {{ source.brand }} {{ source.model }} {{ source.version }}
        </div>
        <div class="VehicleItem-wrapper-progress">
          <b-progress
            :show-value="false"
            :value="weight"
            :type="top.color"
            size="is-large"
            style="width: 100%;"
          ></b-progress>
          <b-progress
            :show-value="false"
            :value="firstRatio * 100"
            :type="top.color"
            size="is-small"
            style="width: 100%;"
          ></b-progress>
        </div>
        <div class="VehicleItem-wrapper-indices">
          <span v-if="!indiceWeight" class="VehicleItem-wrapper-indices-score">{{ source.weight }}</span>
          <span v-else class="VehicleItem-wrapper-indices-score">{{ source.brutWeight }}</span>
          <span class="VehicleItem-wrapper-indices-perthousand">{{ source.proportion }}‰</span>
        </div>
      </div>

      <div
        v-if="source.percentBreak"
        class="VehicleItem-breakpoint"
      >
        {{ $t('break_percent', { percent: source.percentBreak }) }}
        <span>{{ $t('break_represents', { percent: source.percentBreak }) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { NotificationProgrammatic as Notification } from './Notification'
import { mapGetters } from 'vuex'

export default {
  name: 'VehicleItem',
  props: {
    index: Number,
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    maxWeight: {
      type: Number
    },
    firstRatio: {
      type: Number
    },
    indiceWeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tags: [
        { pack: 'far', icon: 'star', color: '#ccc' },
        { pack: 'fas', icon: 'star', color: '#ECBA26' },
        { pack: 'fas', icon: 'check', color: '#4EB83D' },
        { pack: 'fas', icon: 'exclamation', color: '#FF2F2F' },
        { pack: 'fas', icon: 'question', color: '#FF841E' },
        { pack: 'fas', icon: 'angle-double-right', color: '#1984D5' }
      ]
    }
  },
  computed: {
    ...mapGetters('sample', ['top']),
    nextTag() {
      if (this.tags.length > this.source.currentTag + 1) {
        return this.source.currentTag + 1
      }
      return 0
    },
    weight() {
      return this.source.weight / this.maxWeight * 100
    }
  },
  methods: {
    toggleTag() {
      Vue.set(this.source, 'currentTag', this.nextTag)
    },
    vehicleInfos() {
      Notification.open({
        item: this.source,
        color: 'is-top150',
        indefinite: false,
        duration: 18000
      })
    },
    getBrandImgUrl(brand) {
      if (process.env.NODE_ENV === 'test') {
        // if the use case is a unit test
        // exit the method because of require.context is not supported
        return;
      }

      const images = require.context('@/assets/images/brands/', false, /\.png$/)
      let brandImg = ''

      try {
        brandImg = images(`./${brand}.png`)
      } catch (error) {
        brandImg = images(`./no-image.png`)
      }

      return brandImg
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.VehicleItem {
  min-height: 58px;
  padding-bottom: 8px;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;

    &-tag {
      margin-right: 8px;
      font-size: 16px;
      cursor: pointer;
    }

    &-details {
      display: inline-block;
      color: $top760;
      cursor: pointer;
    }

    &-logo {
      width: 32px;
      margin: 0 5px;
      text-align: center;

      img {
        vertical-align: middle;
      }
    }

    &-name {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &-progress {
      flex: 1;

      .progress-wrapper {
        margin-bottom: 5px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &-indices {
      min-width: 75px;
      margin-left: 10px;
      align-self: flex-end;
      text-align: right;

      &-score {
        display: block;
        font-size: 24px;
        font-weight: 700;
        line-height: 18px;
        color: #222222;
      }

      &-perthousand {
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
      }
    }
  }

  &-breakpoint {
    height: 24px;
    margin: 16px 0 8px;
    font-weight: 700;

    span {
      margin-left: 32px;
      font-weight: 300;
      font-style: italic;
      color: #aaa;
    }
  }
}
</style>

<style lang="scss">
.VehicleItem-wrapper-progress .progress.is-small {
  height: 0.5rem;
  opacity: 0.5;
}
</style>