<template>
  <div
    :class="['FiltersApplicableAction', { 'is-disabled': isDisabled }]"
    @click="$emit('input', action.state)"
  >
    <b-icon
      :icon="icon"
      class="FiltersApplicableAction-type"
    ></b-icon>

    <div class="FiltersApplicableAction-icon">
      <b-icon
        :icon="action.icon"
        size="is-large"
      ></b-icon>
    </div>

    <div class="FiltersApplicableAction-label">{{ action.state.header.name }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FiltersApplicableAction',
  props: {
    action: Object,
    icon: String
  },
  computed: {
    ...mapGetters('workshop', ['uniqueBu']),
    isDisabled() {
      return this.action.state.isDisabled || (this.action.state.needUniqueBu && !this.uniqueBu)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.FiltersApplicableAction {
  position: relative;
  width: 150px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &-type {
    position: absolute;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background: $background;
  }

  &-label {
    text-align: center;
  }
}
</style>