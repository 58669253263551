<template>
  <transition name="bounce">
    <div
      v-show="isActive"
      :class="['Notifications-child', color]"
    >
      <div class="Notifications-child-buttons">
        <span
          class="pin"
          :class="{ pressed: pinPressed }"
          @click="addTime(45000)"
        >
          <b-icon icon="thumbtack"></b-icon>
        </span>

        <span
          class="close"
          @click="close"
        >
          <b-icon icon="times"></b-icon>
        </span>
      </div>
      
      <div class="Notifications-child-wrapper Vehicle">
        <div class="Vehicle-rank">#{{ item.rank }}</div>
        <div class="Vehicle-ktype">{{ item.ktype }}</div>
        <div class="Vehicle-infos columns">
          <div class="column">
            <p>
              <span class="Vehicle-infos-title">
                {{ item.brand }} {{ item.model }} {{ item.version }}
              </span>
              <u>{{ i18n.t('infos') }}</u><br/>
              {{ i18n.t('year') }} : {{ item.infos.year }}<br/>
              {{ i18n.t('kw') }} : {{ item.infos.kw }}<br/>
              {{ item.infos.body }}<br/>
              {{ item.infos.energy }}
            </p>
          </div>

          <div
            v-if="item.trend != null"
            class="column"
          >
            <div class="Vehicle-infos-ranks">
              <span class="icon">
                <b-icon icon="chart-line"></b-icon>
              </span>
              <div class="info">
                <span
                  v-for="index in 4"
                  :key="index"
                >
                  <SvgArrow
                    :direction="getArrowDirection"
                    :shape="getArrowShape(index)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NotificationMixin from '@/utils/NotificationMixin.js'
import SvgArrow from '../SvgArrow'
import i18n from '@/lang'

export default {
  name: 'Notification',
  mixins: [NotificationMixin],
  components: {
    SvgArrow
  },
  props: {
    item: Object,
    indefinite: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'is-top150'
    }
  },
  data() {
    return {
      i18n: i18n,
      newDuration: this.duration || 3500
    }
  },
  computed: {
    trendPositive() {
      return this.item.trend > 0
    },
    getArrowDirection() {
      return this.trendPositive ? 'is-up' : 'is-down'
    }
  },
  methods: {
    getArrowShape(index) {
      if (this.trendPositive) {
        return this.item.trend > index ? 'is-full' : this.item.trend > (index - 0.5) ? 'is-half' : 'is-empty'
      }
      return this.item.trend < (index * -1) ? 'is-full' : this.item.trend < ((index * -1) + 0.5) ? 'is-half' : 'is-empty'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.Notifications {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 1000;
  pointer-events: none;

  &-child {
    width: 480px;
    margin-bottom: 1em;
    padding: 0.5em 0.75em 0.75em;
    color: white;
    border: 1px solid rgba(148, 148, 148, 0.8);
    border-radius: 4px;
    overflow: hidden;
    pointer-events: auto;

    &-buttons {
      float: right;
      cursor: pointer;

      .close {
        margin-left: 8px;
      }
      .pin.pressed {
        display: none;
      }
    }
  }
}

.Vehicle {
  @include border-text(1, #000000);

  &-rank {
    font-size: 34px;
    font-weight: 700;
    line-height: 34px;
  }
  &-ktype {
    font-size: 14px;
    line-height: 14px;
  }
  &-infos {
    line-height: 18px;

    &-title {
      display: block;
      margin-bottom: 8px;
      font-weight: 700;
    }

    &.columns {
      margin-top: 8px;
      &:last-child {
        margin-bottom: 0;
      }

      .column {
        padding-top: 0;
        padding-bottom: 0;
      }
      .column + .column {
        border-left: 1px solid white;
      }
    }

    &-ranks {
      display: flex;
      margin: 16px 0;
      font-size: 22px;
      font-weight: bold;

      .icon {
        flex: 1;
      }
      .info {
        flex: 3;
        line-height: 22px;

        &.is-ok {
          color: green;
          @include border-text(1, #FFFFFFBF);
        }
        &.is-not-ok {
          color: red;
          @include border-text(1, #FFFFFFBF);
        }
      }
    }
  }
}
</style>