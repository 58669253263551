export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
      el.remove()
  } else if (typeof el.parentNode !== 'undefined') {
      el.parentNode.removeChild(el)
  }
}

export function digitsDate(dateToChange) {
  return new Date(dateToChange).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export function digitsDateHour(dateToChange) {
  return new Date(dateToChange).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}

export function dataURI_DL(dataURI, filename) {
  const blob = dataURIToBlob(dataURI)
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}

var dataURIToBlob = function(dataURI) {
  const binStr = atob(dataURI.split(',')[1])
  const len = binStr.length
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  let arr = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }

  return new Blob([arr], {
    type: mimeString
  })
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}