<template>
  <section class="Workshop">
    <div class="Workshop-form">
      <b-field 
        :label="$t('workshop_parkname')"
        label-for="parkname"
        label-position="on-border"
        class="Workshop-form-text"
      >
        <b-input
          id="parkname"
          :value="currentWorkshop.parkname"
          @input="setWorkshopAttribute({ attribute: 'parkname', value: $event })"
        ></b-input>
      </b-field>

      <b-field 
        :label="$t('workshop_heading')"
        label-for="heading"
        label-position="on-border"
        class="Workshop-form-text"
      >
        <b-input
          id="heading"
          :value="currentWorkshop.heading"
          @input="setWorkshopAttribute({ attribute: 'heading', value: $event })"
        ></b-input>
      </b-field>

      <b-field 
        :label="$t('workshop_version')"
        label-for="version"
        label-position="on-border"
        class="Workshop-form-text last"
      >
        <b-input
          id="version"
          :value="currentWorkshop.version"
          @input="setWorkshopAttribute({ attribute: 'version', value: $event })"
        ></b-input>
      </b-field>

      <b-field 
        :label="$t('workshop_comment')"
        label-for="comment"
        label-position="on-border"
        class="Workshop-form-textarea"
      >
        <b-input
          id="comment"
          type="textarea"
          :value="currentWorkshop.comment"
          @input="setWorkshopAttribute({ attribute: 'comment', value: $event })"
        ></b-input>
      </b-field>
    </div>

    <WorkshopActions class="Workshop-actions" />

    <FiltersApplied
      v-if="workshopHasFilters"
      :filters="workshop.filters"
    />

    <FiltersApplicable />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersApplied from './FiltersApplied'
import FiltersApplicable from './FiltersApplicable'
import WorkshopActions from './WorkshopActions'

const LISTENER_TIMER = process.env.VUE_APP_LISTENER_TIMER || 60000

export default {
  name: 'Workshop',
  components: {
    FiltersApplied,
    FiltersApplicable,
    WorkshopActions
  },
  data() {
    return {
      currentWorkshop: {}
    }
  },
  mounted() {
    this.setCurrentWorkshop()

    this.getSamplesByIdpark()
    // TODO: s'il n'y a pas de sample avec un statut en cours suite à la première recherche
    // on ne ne relance pas de nouveaux appels
    this.listenToSamples()
  },
  computed: {
    ...mapGetters('workshop', ['workshop', 'workshopSelected', 'sampleOnGoing']),
    workshopHasFilters() {
      if (!this.currentWorkshop.filters) {
        return false
      }

      const filters = this.currentWorkshop.filters
      let hasChoicesSelected = false

      for (const f in filters) {
        if ((filters[f].selectedvalues != null && filters[f].selectedvalues.length > 0) || filters[f].error) {
          hasChoicesSelected = true
        }
      }

      return hasChoicesSelected
    }
  },
  methods: {
    ...mapActions('workshop', ['setWorkshopAttribute', 'getSamples']),
    setCurrentWorkshop() {
      this.currentWorkshop = this.workshop
    },
    listenToSamples() {
      setTimeout(() => {
        this.getSamplesByIdpark()
        this.listenToSamples()
      }, LISTENER_TIMER)
    },
    getSamplesByIdpark() {
      if (this.workshop.idpark === -1 && !this.sampleOnGoing) {
        return
      }

      this.getSamples(this.workshop.idpark).catch(error => {
        this.$buefy.snackbar.open({
          message: error.response.data,
          type: 'is-danger',
          position: 'is-top',
        })
      })
    }
  },
  watch: {
    workshop() {
      this.setCurrentWorkshop()
    }
  }
}
</script>

<style lang="scss" scoped>
.Workshop {
  &-form {
    display: flex;
    flex-wrap: wrap;

    &-text {
      margin-left: 16px;
      width: calc(40% - 8px);

      &:first-child {
        margin-left: 0;
      }
      &.last {
        width: calc(20% - 16px);
      }
    }
    &-textarea {
      margin-left: 0;
      width: 100%;
    }
  }

  &-actions {
    margin: 8px 0;
    text-align: right;
  }
}
</style>