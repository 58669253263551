export default class Park {
  constructor(workshop, bu) {
    this.data = this._constructPark(workshop, bu)
  }

  _constructPark(workshop, bu) {
    const filterList = []

    const filters = workshop.filters

    for (const f in filters) {
      if (filters[f].selectedvalues != null && filters[f].selectedvalues.length > 0) {
        const possiblevalues = []
        const selectedvalues = JSON.parse(JSON.stringify(filters[f].selectedvalues))
        let subname = filters[f].header.subname

        filters[f].possiblevalues.choices.forEach(choice => {
          if (choice.name != null) {
            possiblevalues.push(JSON.parse(JSON.stringify(choice)))
          } else {
            choice.forEach(c => {
              possiblevalues.push(JSON.parse(JSON.stringify(c)))
            })
          }
        })
        filters[f].possiblevalues.fastCheck.forEach(fastCheck => {
          fastCheck.forEach(choice => possiblevalues.push(JSON.parse(JSON.stringify(choice))))
        })
        possiblevalues.forEach(possible => {
          delete possible.name
          delete possible.parents
          delete possible.children
          delete possible.mode
        })
        selectedvalues.forEach(selected => {
          delete selected.name
          delete selected.parents
          delete selected.children
          delete selected.mode
          delete selected.isChecked
          delete selected.isDisabled
        })
        if (filters[f].multipleCheck) {
          subname = selectedvalues[0].code
        }
        const filter = {
          selectedvalues: selectedvalues,
          possiblevalues: possiblevalues,
          filter: filters[f].header.filter,
          subname: subname,
          name: filters[f].header.name,
          type: filters[f].header.type,
          version: filters[f].header.version,
        }
        filterList.push(filter)
      }
    }

    return {
      park: {
        idpark: workshop.idpark,
        parkname: workshop.parkname,
        heading: workshop.heading,
        version: workshop.version,
        comment: workshop.comment,
        bu: bu,
        useronline: JSON.parse(sessionStorage.getItem('vp_token')).decoded.email
      },
      filters: filterList
    }
  }
}
