<template>
  <div class="GroupFastCheck">
    <b-checkbox
      :value="allIsSelected"
      :indeterminate="betweenOneAndAll"
      @input="selectFastCheck($event)"
    >
      {{ $t('fastcheck') }} - {{ label }}
    </b-checkbox>

    <ItemFastCheck
      v-for="item in fastCheckList"
      :key="item.name"
      :item="item"
      :listItem="filter.possiblevalues.fastCheck"
      :choices="choices"
      :type="type"
      @input="$emit('input', $event)"
      class="GroupFastCheck-item"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import ItemFastCheck from './ItemFastCheck'

export default {
  name: 'GroupFastCheck',
  components: {
    ItemFastCheck
  },
  props: {
    filter: {
      type: Object,
      default: () => {}
    },
    choices: {
      type: Array,
      default: () => []
    },
    fastCheckList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'out'
    }
  },
  computed: {
    label() {
      return this.fastCheckList[0].code
    },
    fastCheckSelected() {
      return this.fastCheckList.filter(f => f.isChecked)
    },
    betweenOneAndAll() {
      return this.fastCheckSelected.length > 0 && this.fastCheckSelected.length < this.fastCheckList.length
    },
    allIsSelected() {
      return this.fastCheckSelected.length === this.fastCheckList.length
    }
  },
  methods: {
    selectFastCheck(value) {
      this.fastCheckList.forEach(fastCheck => {
        if (value) {
          Vue.set(fastCheck, 'isChecked', true)
        } else {
          Vue.delete(fastCheck, 'isChecked')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.GroupFastCheck {
  &-item {
    margin: 8px 0;
  }
}
</style>