<template>
  <section class="FilterSection FiltersApplicable">
    <p class="FilterSection-title">{{ $t('filters_applicable') }}</p>
    <hr class="FilterSection-divider">

    <div class="FiltersApplicable-actions">
      <FiltersApplicableAction
        v-for="action in actions_database"
        :key="action.state.header.name"
        :action="action"
        icon="database"
        @input="openModal"
      />
    </div>
    <div class="FiltersApplicable-actions">
      <FiltersApplicableAction
        v-for="action in actions_car"
        :key="action.state.header.name"
        :action="action"
        icon="car"
        @input="openModal"
      />
    </div>
    <div class="FiltersApplicable-actions">
      <FiltersApplicableAction
        v-for="action in actions_percent"
        :key="action.state.header.name"
        :action="action"
        icon="percent"
        @input="openModal"
      />
    </div>

    <b-modal
      v-model="isCardModalActive"
      :width="modalWidth"
      scroll="keep"
      :can-cancel="false"
    >
      <FiltersModal
        :filter="currentFilter"
        @input="isCardModalActive = false"
        :style="`width: ${modalWidth}px;`"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import FiltersApplicableAction from './FiltersApplicableAction'
import FiltersModal from './FiltersModal'

export default {
  name: 'FiltersApplicable',
  components: {
    FiltersApplicableAction,
    FiltersModal
  },
  data() {
    return {
      isCardModalActive: false,
      currentFilter: {},
      modalWidth: 1100
    }
  },
  computed: {
    ...mapGetters(
      'workshop', [
        'filterAge',
        'filterBody',
        'filterBrand',
        'filterCluster',
        'filterCountry',
        'filterCv',
        'filterEnergy',
        'filterMoney',
        'filterTop',
        'filterUniverse',
        'uniqueBu',
      ]
    ),
    actions() {
      return [
        { state: this.filterCountry, icon: 'flag' },
        { state: this.filterTop, icon: 'chevron-up' },
        { state: this.filterBrand, icon: 'copyright' },
        { state: this.filterMoney, icon: 'euro-sign' },
        { state: this.filterAge, icon: 'baby-carriage' },
        { state: this.filterCluster, icon: 'code-branch' },
        { state: this.filterUniverse, icon: 'folder' },
        { state: this.filterCv, icon: 'oil-can' },
        { state: this.filterEnergy, icon: 'gas-pump' },
        { state: this.filterBody, icon: 'car' },
        // { label: 'Profondeur passages', icon: 'layer-group' },
      ]
    },
    actions_car() {
      return this.actions.filter(action => action.state != null ? action.state.section === 'KTYPNR' : false)
    },
    actions_database() {
      return this.actions.filter(action => action.state != null ? action.state.section === 'CHANGE_PARK' : false)
    },
    actions_percent() {
      return this.actions.filter(action => action.state != null ? action.state.section === 'PROPORTION' : false)
    }
  },
  methods: {
    openModal(state) {
      if (state == null || state.isDisabled || (state.needUniqueBu && !this.uniqueBu)) {
        return;
      }
      this.currentFilter = state
      this.isCardModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.FiltersApplicable {
  padding-bottom: $bottom-size;

  &-actions {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>