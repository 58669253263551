<template>
  <section class="Tabs">
    <ul class="Tabs-items">
      <li 
        v-for="(workshop, index) in workshops" :key="index"
        :class="{ 'is-active': index === workshopSelected }"
        @click="clickWorkshop(index, $event)"
      >
        {{ workshop.parkname }}
        <span
          v-if="countWorkshops > 1"
          class="Tabs-items-closable"
          @click="removeWorkshop(index)"
        >
          <b-icon icon="times"></b-icon>
        </span>
      </li>
      
      <li
        v-if="countWorkshops < 4"
        @click="addWorkshop"
      >
        <b-icon icon="plus"></b-icon>
      </li>

      <li class="info">
        {{ $t('tabs_max') }}
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheTabs',
  computed: {
    ...mapGetters('workshop', ['workshops', 'workshopSelected', 'countWorkshops']),
  },
  methods: {
    ...mapActions('workshop', ['setWorkshopSelected', 'addWorkshop', 'removeWorkshop']),
    clickWorkshop(index, event) {
      if (event.target.localName === 'li') {
        this.setWorkshopSelected(index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.Tabs {
  &-items {
    display: flex;
    flex-grow: 1;
    padding-left: 128px;
    border-bottom: 1px solid $secondary;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -1px;
      padding: 0.5em 1em;
      border: 1px solid transparent;
      cursor: pointer;

      &:not(.info):hover, &:not(.info):focus {
        background: $background;
        border-bottom-color: $secondary;
      }

      &.is-active {
        color: $primary;
        background: white;
        border: 1px solid $secondary;
        border-radius: 4px 4px 0 0;
        border-bottom-color: transparent;

        &:hover, &:focus {
          background: white;
          border-bottom-color: transparent;
        }
      }

      &.last {
        margin-left: auto;
        margin-right: 10px;
      }

      &.info {
        color: rgb(182, 182, 182);
        font-style: italic;
        cursor: default;
      }
    }

    &-closable {
      margin-left: 8px;
    }
  }
}
</style>