import Vue from 'vue'

import apiPark from '@/api/park'
import apiSample from '@/api/sample'

import ageModel from '@/models/filterAge'
import bodyModel from '@/models/filterBody'
import brandModel from '@/models/filterBrand'
import clusterModel from '@/models/filterCluster'
import cvModel from '@/models/filterCv'
import energyModel from '@/models/filterEnergy'
import moneyModel from '@/models/filterMoney'
import topModel from '@/models/filterTop'
import universeModel from '@/models/filterUniverse'

import parkModel from '@/models/park'

import { deepEqual } from '@/utils/helpers'

const state = {
  workshops: [{
    idpark: -1,
    parkname: 'Workshop 1',
    heading: '',
    version: '',
    comment: '',
    filters: {},
    bu: {},
    park: null,
    samples: []
  }],
  selected: 0,
  lastIndex: 1,
  parks: [],
  samples: []
}

const mutations = {
  SET_SELECTED(state, index) {
    state.selected = index

    // TODO: Le sample doit changer
  },
  ADD_WORKSHOP(state) {
    state.selected = state.workshops.length
    state.lastIndex++
    state.workshops.push({
      idpark: -1,
      parkname: `Workshop ${state.lastIndex}`,
      heading: '',
      comment: '',
      version: '',
      filters: {},
      bu: {},
      park: null
    })
  },
  REMOVE_WORKSHOP(state, index) {
    if (state.workshops.length <= 1) {
      return
    }
    
    if (state.selected === index) {
      state.selected = 0
    } else if (state.selected > index) {
      state.selected--
    }
    
    state.workshops.splice(index, 1)
  },
  SET_WORKSHOP_ATTRIBUTE(state, { attribute, value }) {
    state.workshops[state.selected][attribute] = value

    if (state.workshops[state.selected].park != null) {
      state.workshops[state.selected].park.data.park[attribute] = value
    }
  },
  SET_WORKSHOP_FILTERS(state, { filters, keep }) {
    if (!keep) {
      // Il ne faut pas garder les elements selectionnes
      // On boucle sur tous les filtres et tous les choix possible pour s'assurer de tout vider
      for (const f in filters) {
        delete filters[f].selectedvalues
        if (filters[f].multipleCheck) {
          filters[f].possiblevalues.choices.forEach(choiceList => {
            choiceList.forEach(choice => {
              delete choice.isChecked
              delete choice.isDisabled
            })
          })
        } else {
          filters[f].possiblevalues.choices.forEach(choice => {
            delete choice.isChecked
            delete choice.isDisabled
          })
        }
      }
    }

    state.workshops[state.selected].filters = filters
  },
  UPDATE_WORKSHOP_FILTER(state, filter) {
    Vue.set(state.workshops[state.selected].filters, filter.header.name, filter)
  },
  REMOVE_WORKSHOP_FILTER(state, name) {
    let filter = state.workshops[state.selected].filters[name]

    Vue.delete(filter, 'selectedvalues')

    filter.possiblevalues.choices.forEach(choice => {
      if (choice.length > 0) {
        choice.forEach(c => {
          Vue.delete(c, 'isChecked')
          Vue.delete(c, 'isDisabled')
        })
      } else {
        Vue.delete(choice, 'isChecked')
        Vue.delete(choice, 'isDisabled')
      }
    })

    filter.possiblevalues.fastCheck.forEach(listFastCheck => {
      listFastCheck.forEach(choice => {
        Vue.delete(choice, 'isChecked')
        Vue.delete(choice, 'isDisabled')
      })
    })
  },
  SET_WORKSHOP_BU(state, bu) {
    state.workshops[state.selected].bu = bu
  },
  SET_WORKSHOP_FILTERS_BU(state, filters) {
    const filters_bu = ['age', 'cluster', 'top', 'universe']
    const workshopFilters = state.workshops[state.selected].filters

    for (const f in workshopFilters) {
      if (filters_bu.includes(f)) {
        Vue.set(state.workshops[state.selected].filters, f, filters[f])
      }
    }
  },
  LOAD_PARK(state, data) {
    state.workshops[state.selected].parkname = data.park[0].parkname
    state.workshops[state.selected].heading = data.park[0].heading
    state.workshops[state.selected].version = data.park[0].version
    state.workshops[state.selected].comment = data.park[0].comment
    state.workshops[state.selected].idpark = data.park[0].idpark

    data.filters.forEach(filter => {
      let index = state.workshops[state.selected].filters[filter.name]
      if (index !== undefined) {
        let newFilter = null
        switch (filter.name) {
          case 'age':
            newFilter = new ageModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'body':
            newFilter = new bodyModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'brand':
            newFilter = new brandModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'cluster':
            newFilter = new clusterModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'cv':
            newFilter = new cvModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'energy':
            newFilter = new energyModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'money':
            newFilter = new moneyModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'top':
            newFilter = new topModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          case 'universe':
            newFilter = new universeModel(state.workshops[state.selected].filters[filter.name], filter)
            break
          // Cas country : Rien à faire, le cas est déjà géré dans le module filter pour le rappel des filtres avec la bonne BU
        }
        if (newFilter != null) {
          Vue.set(state.workshops[state.selected].filters, filter.name, newFilter)
        }
      } else {
        // Le filtre sauvegardé n'existe plus dans les dernières versions, il faut prévenir l'utilisateur
        let newFilter = {
          error: true,
          messages: [`Le filtre "${filter.name}" n'existe pas ou plus, il a été supprimé`]
        }
        state.workshops[state.selected].filters[filter.name] = newFilter
      }
    })
  },
  GET_PARKS(state, parks) {
    state.parks = parks
  },
  SET_PARK(state, bu) {
    const park = new parkModel(state.workshops[state.selected], bu)

    Vue.set(state.workshops[state.selected], 'park', park)
  },
  SET_SAMPLES(state, samples) {
    Vue.set(state.workshops[state.selected], 'samples', samples)
  },
  RESET_WORKSHOP(state) {
    state.selected = 0
    state.lastIndex = 1
    state.parks = []
    state.samples = []

    state.workshops.length = 1
    state.workshops[0].idpark = -1
    state.workshops[0].parkname = 'Workshop 1'
    state.workshops[0].heading = ''
    state.workshops[0].version = ''
    state.workshops[0].comment = ''
    state.workshops[0].filters = {}
    state.workshops[0].bu = {}
    state.workshops[0].park = null
    state.workshops[0].samples = []
  }
}

const getters = {
  workshops: state => state.workshops,
  workshopSelected: state => state.selected,
  countWorkshops: state => state.workshops.length,
  workshop: state => state.workshops[state.selected],
  filters: (_, getters) => getters.workshop.filters,
  filterAge: (_, getters) => getters.filters.age,
  filterBody: (_, getters) => getters.filters.body,
  filterBrand: (_, getters) => getters.filters.brand,
  filterCluster: (_, getters) => getters.filters.cluster,
  filterCountry: (_, getters) => getters.filters.country,
  filterCv: (_, getters) => getters.filters.cv,
  filterEnergy: (_, getters) => getters.filters.energy,
  filterMoney: (_, getters) => getters.filters.money,
  filterTop: (_, getters) => getters.filters.top,
  filterUniverse: (_, getters) => getters.filters.universe,
  bu: (_, getters) => getters.workshop.bu != null && getters.workshop.bu.primary != null ? getters.workshop.bu.primary.cd_bu : null,
  uniqueBu: (_, getters) => getters.workshop.bu != null ? getters.workshop.bu.isUnique : false,
  parks: state => state.parks,
  park: (_, getters) => getters.workshop.park,
  isCurrentParkSaved: (_, getters) => {
    if (getters.park == null) {
      return false
    }
    const currentPark = new parkModel(getters.workshop, getters.bu)
    return deepEqual(getters.park.data, currentPark.data)
  },
  samples: (_, getters) => getters.workshop.samples,
  isSampleActive: (_, getters) => {
    if (getters.samples == null || getters.samples.length === 0) {
      return false
    }
    return getters.samples[0].statut === 'DONE'
  },
  sampleOnGoing: (_, getters) => {
    if (getters.samples == null || getters.samples.length === 0) {
      return false
    }
    return getters.samples[0].statut == null || getters.samples[0].statut === 'ONGOING'
  }
}

const actions = {
  setWorkshopSelected({ commit }, index) {
    commit('SET_SELECTED', index)
  },
  addWorkshop({ commit, rootGetters }) {
    commit('ADD_WORKSHOP')
    commit('SET_WORKSHOP_FILTERS', { filters: JSON.parse(JSON.stringify(rootGetters['filter/filters'])), keep: false })
    commit('SET_WORKSHOP_BU', JSON.parse(JSON.stringify(rootGetters['filter/listBuOnLoad'])))
  },
  removeWorkshop({ commit }, index) {
    commit('REMOVE_WORKSHOP', index)
  },
  setWorkshopAttribute({ commit }, { attribute, value }) {
    commit('SET_WORKSHOP_ATTRIBUTE', { attribute, value })
  },
  setWorkshopFilter({ commit }, filter) {
    commit('UPDATE_WORKSHOP_FILTER', filter)
  },
  removeWorkshopFilter({ commit, rootGetters }, name) {
    commit('REMOVE_WORKSHOP_FILTER', name)

    if (name === 'country') {
      commit('SET_WORKSHOP_BU', JSON.parse(JSON.stringify(rootGetters['filter/listBuOnLoad'])))
    }
  },
  savePark({ commit, getters }) {
    commit('SET_PARK', getters.bu)

    return apiPark.savePark(getters.park.data).then(response => {
      commit('SET_WORKSHOP_ATTRIBUTE', { attribute: 'idpark', value: response.data.idpark.idpark })
      commit('SET_PARK', getters.bu)
    })
  },
  updatePark({ commit, getters }) {
    commit('SET_PARK', getters.bu)

    return apiPark.updatePark(getters.park.data).then(response => {
      commit('SET_WORKSHOP_ATTRIBUTE', { attribute: 'idpark', value: response.data.idpark.idpark })
      commit('SET_PARK', getters.bu)
    })
  },
  getParks({ commit }) {
    return apiPark.getParks().then(response => {
      commit('GET_PARKS', response.data.parks)
    })
  },
  loadPark({ commit, dispatch, getters }, id) {
    return apiPark.loadPark(id).then(response => {
      commit('SET_WORKSHOP_BU', response.data.park[0].bu)

      dispatch('filter/getFiltersByBu', {
        bu: response.data.park[0].bu,
        country: response.data.filters.filter(f => f.name === 'country')
      }, { root: true }).then(() => {
        commit('LOAD_PARK', response.data)
        commit('SET_WORKSHOP_ATTRIBUTE', { attribute: 'idpark', value: response.data.park[0].idpark })
        commit('SET_PARK', getters.bu)
        dispatch('getSamples', getters.workshop.idpark)
      })
    })
  },
  getSamples({ commit, dispatch }, id) {
    return apiSample.getSampleHeaders(id).then(response => {
      const samples = Array.isArray(response.data.samples) ? response.data.samples : []

      commit('SET_SAMPLES', samples)

      if (samples.length > 0 && samples[0].statut === 'DONE') {
        dispatch('sample/setMnemoniqueUuid', {
          mnemonique: samples[0].mnemonique,
          uuid: samples[0].uuid
        }, { root: true })
      }
    })
  },
  createSample({ getters }) {
    const sample = {
      sample: {
        ...getters.park.data.park,
        samplename: getters.park.data.park.parkname
      },
      filters: getters.park.data.filters
    }
    return apiSample.createSampleHeader(sample)
  },
  resetWorkshop({ commit }) {
    commit('RESET_WORKSHOP')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}