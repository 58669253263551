<template>
  <section class="SampleModal modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ title }}
      </div>
    </header>

    <section class="SampleModal-body modal-card-body">
      <template v-if="isCreating">
        <p v-if="errorOccured" class="is-danger">{{ errorMessage }}</p>
        <p v-else>{{ $t('sample_ongoing') }}</p>
      </template>

      <p class="SampleModal-body-histo">{{ $t('sample_histo') }}</p>
      <ul class="SampleModal-body-list">
        <li
          v-for="sample in samples"
          :key="sample.idsample"
        >
          <span class="name">{{ sample.mnemonique }}</span>
          <span class="date">{{ sample.creationdate | localeDateString }}</span>
          <template v-if="sample.statut">[{{ sample.statut }}]</template>
          {{ sample.uuid }}
        </li>
      </ul>
    </section>

    <footer class="modal-card-foot">
      <button
        class="button"
        @click="$emit('input', false)"
      >{{ $t('close') }}</button>
    </footer>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { digitsDateHour } from '@/utils/helpers'
import i18n from '@/lang'

export default {
  name: 'SampleCreation',
  props: {
    isCreating: {
      type: Boolean,
      default: false
    },
    errorOccured: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('workshop', ['samples']),
    title() {
      return this.isCreating ? i18n.t('sample_creation') : i18n.t('sample_histo_title')
    }
  },
  filters: {
    localeDateString(value) {
      return digitsDateHour(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.SampleModal {
  text-align: left;

  &-body {
    &-histo {
      margin-top: 16px;
    }

    &-list {
      list-style-type: none;

      li {
        color: grey;

        &:first-child {
          color: #2c3e50;
          font-weight: bold;
        }

        .name {
          display: inline-block;
          min-width: 380px;
        }
        .date {
          display: inline-block;
          min-width: 180px;
        }
      }
    }
  }
}
</style>