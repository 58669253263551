<template>
  <section class="FilterSection FiltersApplied">
    <p class="FilterSection-title">{{ $t('filters_applied') }}</p>
    <hr class="FilterSection-divider">

    <FiltersAppliedResume
      v-for="(filter, index) in filters"
      :key="index"
      :filter="filter"
      @input="openModal"
      class="FiltersApplied-resume"
    />

    <b-modal
      v-model="isCardModalActive"
      :width="modalWidth"
      scroll="keep"
      :can-cancel="false"
    >
      <FiltersModal
        :filter="currentFilter"
        @input="isCardModalActive = false"
        :style="`width: ${modalWidth}px;`"
      />
    </b-modal>
  </section>
</template>

<script>
import FiltersAppliedResume from './FiltersAppliedResume'
import FiltersModal from './FiltersModal'

export default {
  name: 'FiltersApplied',
  components: {
    FiltersAppliedResume,
    FiltersModal
  },
  props: {
    filters: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {
      isCardModalActive: false,
      currentFilter: {},
      modalWidth: 1100
    }
  },
  methods: {
    openModal(val) {
      this.currentFilter = val
      this.isCardModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
.FiltersApplied {
  margin-bottom: 32px;

  &-resume {
    margin-bottom: 16px;
  }
}
</style>