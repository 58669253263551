import Vue from 'vue'
import Vuex from 'vuex'

import workshop from './modules/workshop'
import filter from './modules/filter'
import sample from './modules/sample'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    workshop,
    filter,
    sample,
    user
  }
})
