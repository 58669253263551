<template>
  <div>
    <slot></slot>
    
    <b-progress
      :show-value="false"
      :value="progressValue"
      :type="color"
      style="width: 100%;"
    ></b-progress>
  </div>
</template>

<script>
export default {
  name: 'FiltersProgress',
  props: {
    type: {
      type: String,
      default: 'out',
      validator(value) {
        return [
          'out',
          'in'
        ].indexOf(value) > -1
      }
    },
    choices: Array,
    reverse: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'is-primary'
    }
  },
  computed: {
    progressValue() {
      let volume = 0

      this.choices.forEach(check => {
        if (this.type === 'out' && !this.reverse && (!check.isDisabled && !check.isChecked)) {
          volume += check.immat_volume_ratio
        } else if (this.type === 'out' && this.reverse && (check.isDisabled || check.isChecked)) {
          volume += check.immat_volume_ratio
        } else if (this.type === 'in' && !this.reverse && (check.isDisabled || check.isChecked)) {
          volume += check.immat_volume_ratio
        } else if (this.type === 'in' && this.reverse && (!check.isDisabled && !check.isChecked)) {
          volume += check.immat_volume_ratio
        }
      })

      return volume
    }
  }
}
</script>