const constructChoices = (choices, attribute, mode = 'equals', orderAttribute = null, isArray = false) => {
  choices.map(choice => {
    choice.name = isArray ? choice[attribute][0] : choice[attribute]
    choice.mode = mode
    if (mode === 'equals') {
      choice.parents = choice._source
    } else if (mode === 'interval') {
      choice.parents = [choice.bornea, choice.borneb]
    }
  })
  if (orderAttribute) {
    choices.sort((a, b) => a[orderAttribute] - b[orderAttribute])
  }
  return choices
}

const constructFastCheckList = (list, attribute, mode = 'equals', orderAttribute = null) => {
  const fastCheckList = []
  list.forEach(item => {
    let fastCheckArray = []
    if (item.data) {
      fastCheckArray = item.data.body.choices
    } else {
      fastCheckArray = item
    }

    fastCheckArray.map(fastCheck => {
      fastCheck.name = fastCheck[attribute]
      if (mode === 'equals') {
        fastCheck.children = fastCheck._source
      } else if (mode === 'interval') {
        fastCheck.children = [fastCheck.bornea, fastCheck.borneb]
      }
    })
    if (orderAttribute) {
      fastCheckArray.sort((a, b) => a[orderAttribute] - b[orderAttribute])
    }

    fastCheckList.push(fastCheckArray)
  })
  return fastCheckList
}

const groupFastCheckByCode = (list) => {
  const codeList = []
  const fastCheckArray = []

  list.forEach(fastCheck => {
    if (!codeList.includes(fastCheck.code)) {
      codeList.push(fastCheck.code)
    }
  })

  codeList.forEach(code => {
    let fastCheckByCode = []
    list.forEach(fastCheck => {
      if (fastCheck.code === code) {
        fastCheckByCode.push(fastCheck)
      }
    })
    fastCheckArray.push(fastCheckByCode)
  })

  return fastCheckArray
}

const getValidFilter = (filter, selectedvalues, possiblevalues) => {
  const choicesWithoutDeleted = []
  const fastCheckListWithoutDeleted = []
  const messages = []
  let recoveryFailed = false
  let multipleCheck = false

  possiblevalues.choices.forEach(choice => {
    if (choice.name != null) {
      if (filter.possiblevalues.choices.filter(c => c.name === choice.name).length > 0) {
        choicesWithoutDeleted.push(choice)
      } else {
        // Choice removed from final list
        if (choice.isChecked || choice.isDisabled) {
          recoveryFailed = true
          messages.push(`Le choix "${choice.name}" n'existe plus sur le filtre "${filter.header.name}", le choix a été supprimé`)
        }
      }
    } else {
      multipleCheck = true
      choice.forEach(ch => {
        if (filter.possiblevalues.choices.filter(c => c.name === ch.name).length > 0) {
          choicesWithoutDeleted.push(ch)
        } else {
          // Choice removed from final list
          if (ch.isChecked || ch.isDisabled) {
            recoveryFailed = true
            messages.push(`Le choix "${ch.name}" n'existe plus sur le filtre "${filter.header.name}", le choix a été supprimé`)
          }
        }
      })
    }
  })
  possiblevalues.choices = choicesWithoutDeleted

  if (possiblevalues.fastCheck.length > 0) {
    possiblevalues.fastCheck.forEach(fastCheckList => {
      let fastCheckWithoutDeleted = []
      fastCheckList.forEach(fastCheck => {
        filter.possiblevalues.fastCheck.forEach(filterFastCheckList => {
          if (filterFastCheckList.filter(f => f.name === fastCheck.name).length > 0) {
            fastCheckWithoutDeleted.push(fastCheck)
          }
        })
      })
      fastCheckListWithoutDeleted.push(fastCheckWithoutDeleted)
    })
    possiblevalues.fastCheck = fastCheckListWithoutDeleted
  }

  if (multipleCheck) {
    let choicesConcat = []
    possiblevalues.choices.forEach(choice => {
      choicesConcat = choicesConcat.concat(choice.filter(choice => choice.isChecked || choice.isDisabled))
    })
    selectedvalues = choicesConcat
  } else {
    selectedvalues = possiblevalues.choices.filter(choice => choice.isChecked || choice.isDisabled)
  }

  if (selectedvalues.length === 0) {
    recoveryFailed = true
    messages.push(`Le filtre "${filter.header.name}" ne contient plus aucun choix valide, le filtre a été supprimé`)
  }

  return {
    possiblevalues: possiblevalues,
    selectedvalues: selectedvalues,
    error: recoveryFailed,
    messages: messages
  }
}

const getValidHeader = (filter, park, selectedvalues, possiblevalues) => {
  let error = false
  const messages = []
  
  if (filter.header.type.type !== park.typetype) {
    error = true
    messages.push(`Le type du filtre "${filter.header.name}" n'est plus "${park.typetype}",  mais "${filter.header.type.type}"`)
    messages.push(`Les sélections du filtre ont été supprimées`)
  }

  if (filter.header.type.maximum !== park.typemax) {
    if (filter.header.type.maximum === 'ALL-minus1') {
      if (selectedvalues.length >= possiblevalues.choices.length) {
        error = true
        messages.push(`La sélection max du filtre "${filter.header.name}" n'est plus "${park.typemax}",  mais "${filter.header.type.maximum}"`)
        messages.push(`Il faut modifier la sélection`)
      }
    }
  }

  if (filter.header.type.minimum !== park.typemin) {
    if (selectedvalues.length < filter.header.type.minimum) {
      error = true
      messages.push(`La sélection min du filtre "${filter.header.name}" n'est plus "${park.typemin}",  mais "${filter.header.type.minimum}"`)
      messages.push(`Il faut modifier la sélection`)
    }
  }

  if (error) {
    selectedvalues = []
    possiblevalues.choices.forEach(choice => {
      if (choice.name != null) {
        delete choice.isChecked
        delete choice.isDisabled
      } else {
        choice.map(c => {
          delete c.isChecked
          delete c.isDisabled
        })
      }
    })
    possiblevalues.fastCheck.forEach(fastCheck => {
      fastCheck.map(f => {
        delete f.isChecked
      })
    })
  }

  return {
    selectedvalues,
    possiblevalues,
    error,
    messages
  }
}

const getValidPark = (filter, park, selectedvalues, possiblevalues) => {
  const validHeader = getValidHeader(filter, park, selectedvalues, possiblevalues)

  if (validHeader.error) {
    return validHeader
  }

  return getValidFilter(filter, selectedvalues, possiblevalues)
}

export { constructChoices, constructFastCheckList, groupFastCheckByCode, getValidPark }