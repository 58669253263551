<template>
  <section class="Sample">
    <p>{{ mnemonique }}</p>

    <SampleHeader
      v-model="indiceWeight"
      class="Sample-header"
    />

    <div class="Sample-wrapper" ref="wrapper">
      <virtual-list
        ref="virtualScroller"
        data-key="index"
        :data-sources="sample"
        :data-component="itemComponent"
        :extra-props="extraProps"
        :estimate-size="66"
        :style="`height: ${listHeight}px;overflow-y: auto;`"
        :class="['List', top.color]"
        @scroll="handleScroll"
      />
    </div>

    <SampleFooter />
  </section>
</template>

<script>
import SampleHeader from './SampleHeader'
import SampleFooter from './SampleFooter'
import VehicleItem from './VehicleItem'
import VirtualList from 'vue-virtual-scroll-list'
import { mapGetters, mapActions } from 'vuex'

const ITEMS_PER_PAGE = parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) || 20

export default {
  name: 'Sample',
  components: {
    SampleHeader,
    SampleFooter,
    VirtualList
  },
  data() {
    return {
      itemComponent: VehicleItem,
      indiceWeight: false,
      listHeight: null
    }
  },
  mounted() {
    window.scrollTo(0, 0)

    this.$nextTick(function() {
      this.calculateListHeight()
      window.addEventListener('resize', this.calculateListHeight)
    })
  },
  computed: {
    ...mapGetters('workshop', ['workshop']),
    ...mapGetters('sample', ['sample', 'total', 'top', 'mnemonique']),
    extraProps() {
      if (this.sample.length === 0) {
        return null
      }
      
      return {
        maxWeight: this.sample[0].weight,
        firstRatio: this.sample[0].weight / this.sample[0].weight,
        indiceWeight: this.indiceWeight
      }
    },
    sampleOfSample() {
      return this.sample.slice(0, 10)
    }
  },
  methods: {
    ...mapActions('workshop', ['getSamples']),
    ...mapActions('sample', ['fetchSample', 'scrollSample']),
    calculateListHeight() {
      this.fetchSample().then(() => {
        if (this.$refs.wrapper == null) {
          return
        }
        
        const windowHeight = window.innerHeight
        const top = this.$refs.wrapper.getBoundingClientRect().top
  
        let bottom = 0
        document.getElementsByClassName('footer').forEach(f => bottom += f.offsetHeight)
        
        this.listHeight = windowHeight - (top + bottom)
      })
    },
    handleScroll(_, range) {
      const countPages = Math.ceil(this.total / ITEMS_PER_PAGE)
      const currentPage = Math.ceil((range.start + 10) / ITEMS_PER_PAGE) || 1

      if (currentPage > 0 && currentPage <= countPages) {
        this.scrollSample(currentPage)
      }
    },
    fetch() {
      this.calculateListHeight()
      this.getSamples(this.workshop.idpark).then(() => {
        this.fetchSample().finally(() => {
          if (this.$refs.virtualScroller.$el.scrollTop !== 0) {
            // Triggers handleScroll with the right params
            this.$refs.virtualScroller.$el.scrollTop--
          }
        })
      })
    },
    scrollToTop() {
      this.$refs.virtualScroller.scrollToIndex(0)
    }
  },
  provide() {
    return {
      scrollToTop: this.scrollToTop
    }
  }
}
</script>

<style lang="scss" scoped>
.Sample {
  &-header {
    margin-bottom: 16px;
  }
}
</style>
