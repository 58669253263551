<template>
  <section class="WorkshopModal modal-card">
    <header class="WorkshopModal-header modal-card-head">
      <div class="modal-card-title">
        {{ $t('load_park') }}
      </div>
    </header>

    <section class="WorkshopModal-body modal-card-body">
      <b-field
        v-if="parksSorted.length > 0"
        :label="$t('select_park_to_load')"
        label-for="park"
        label-position="on-border"
        class="WorkshopModal-body-field"
      >
        <b-select
          v-model="parkSelected"
          id="park"
          :placeholder="$t('select_park')"
          expanded
        >
          <optgroup 
            v-for="parks in parksSorted"
            :key="parks.heading"
            :label="parks.heading || $t('no_heading')"
          >
            <option
              v-for="park in parks.parks"
              :key="park.idpark"
              :value="park.idpark"
            >
              {{ park.parkname }}<template v-if="park.version !== ''"> v{{ park.version }}</template>
            </option>
          </optgroup>
        </b-select>
      </b-field>

      <b-tooltip :label="`${$t('sort_by')} ${sortValues[nextSort]}`" position="is-right" always>
        <b-button icon-right="sort" @click="toggleSortBy"></b-button>
      </b-tooltip>
    </section>

    <footer class="modal-card-foot">
      <button
        class="button is-success"
        @click="getPark"
      >{{ $t('valid') }}</button>

      <button
        class="button"
        @click="closeModal"
      >{{ $t('cancel') }}</button>
    </footer>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import i18n from '@/lang'

export default {
  name: 'WorkshopLoadPark',
  props: {
    parks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      parkSelected: -1,
      sortValues: ['parkname', 'creationdate'],
      sortBy: 0
    }
  },
  computed: {
    parksByHeading() {
      let parks = []
      
      this.parks.forEach(park => {
        let heading = parks.filter(p => p.heading === park.heading)
        if (heading.length > 0) {
          heading[0].parks.push(park)
        } else {
          parks.push({
            heading: park.heading,
            parks: [park]
          })
        }
      })
      
      parks.sort((a, b) => {
        if (a.heading > b.heading) {
          return 1
        } else if (a.heading < b.heading) {
          return -1
        }
        return 0
      })
      
      return parks
    },
    parksSorted() {
      this.parksByHeading.forEach(parksByHeading => {
        parksByHeading.parks.sort((a, b) => {
          if (a[this.sortValues[this.sortBy]] > b[this.sortValues[this.sortBy]]) {
            return 1
          } else if (a[this.sortValues[this.sortBy]] < b[this.sortValues[this.sortBy]]) {
            return -1
          }
          
          // Ajout d'un tri par id si les dates sont egales (ne change rien au tri par nom)
          if (a.idpark > b.idpark) {
            return 1
          } else if (a.idpark < b.idpark) {
            return -1
          }
          return 0
        })
      })

      return this.parksByHeading
    },
    nextSort() {
      if (this.sortValues.length > this.sortBy + 1) {
        return this.sortBy + 1
      }
      return 0
    },
  },
  methods: {
    ...mapActions('workshop', ['loadPark']),
    toggleSortBy() {
      this.sortBy = this.nextSort
    },
    getPark() {
      if (this.parkSelected === -1) {
        this.$buefy.snackbar.open({
          message: i18n.t('invalid_park_selected'),
          type: 'is-danger',
          position: 'is-top',
        })
        return
      }
      this.loadPark(this.parkSelected).then(() => {
        this.closeModal()
      }).catch(() => {
        this.$buefy.snackbar.open({
          message: i18n.t('error_load_park'),
          type: 'is-danger',
          position: 'is-top',
        })
      })
    },
    closeModal() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.WorkshopModal {
  text-align: left;

  &-body {
    display: flex;
    align-items: flex-start;

    &-field {
      min-width: 250px;
      margin-right: 16px;
    }
  }
}
</style>
