<template>
  <div class="Home">
    <header class="Home-header">
      <div class="Home-header-menu">
        <div class="title">
          {{ businessBrand }}
          <template v-if="workshop.bu.primary">{{ workshop.bu.primary.cd_bu }}</template>
        </div>
        <a @click="resetView">{{ $t('return_home') }} <i class="fas fa-sign-out-alt"></i></a>
      </div>

      <div class="Home-header-logo">
        <img
          alt="VehPark logo"
          src="../assets/images/logo.png"
        />
      </div>
    </header>

    <main>
      <TheTabs />
      <div class="Home-wrapper">
        <Sidebar
          class="Home-wrapper-sidebar"
          :options="options"
          ref="sidebar"
        />

        <Workshop
          v-show="currentPage === 'workshop'"
          class="Home-wrapper-content"
        />
        <Sample
          v-show="currentPage === 'sample'"
          class="Home-wrapper-content"
          ref="sample"
        />
      </div>
    </main>
    <footer class="footer">
      <div class="container">
        <p>
          {{ $t('app_version') }}
          <a :href="hrefDoc" target="_blank">{{ version }}</a>
        </p>
      </div>
    </footer>
    
    <b-loading v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import TheTabs from '@/components/TheTabs.vue'
import Sidebar from '@/components/Sidebar.vue'
import Workshop from '@/components/Workshop.vue'
import Sample from '@/components/Sample.vue'

import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

import api from '@/api/common.js'
import { version } from '../../package.json'

export default {
  name: 'Home',
  components: {
    TheTabs,
    Sidebar,
    Workshop,
    Sample
  },
  data() {
    return {
      hrefDoc: 'Spec - Gestion des parcs.pdf',
      version: version,
      isLoading: true,
      currentPage: 'workshop',
      options: [
        { name: 'filter', icon: 'filter', action: () => { this.currentPage = 'workshop' } },
        { name: 'car', icon: 'car', action: () => { this.currentPage = 'sample' } }
      ],
      businessBrand: null
    }
  },
  mounted() {
    api.getPresignedDoc().then(response => {
      this.hrefDoc = response.data.result
    })
    this.getFilters().finally(() => {
      this.isLoading = false
    }).catch(error => {
      this.$buefy.notification.open({
        message: error,
        type: 'is-danger',
        position: 'is-top',
      })
    })
    this.businessBrand = sessionStorage.getItem('vp_buisness_brand')
    // on bloque l'accès à l'option si il n'y a pas d'échantillon actif (il en existe au moins un et il est terminé)
    Vue.set(this.options[1], 'isDisabled', !this.isCurrentParkSaved || !this.isSampleActive)
  },
  computed: {
    ...mapGetters('workshop', ['workshop', 'workshopSelected', 'isCurrentParkSaved', 'isSampleActive'])
  },
  methods: {
    ...mapActions('filter', ['getFilters', 'resetFilters']),
    ...mapActions('workshop', ['resetWorkshop']),
    resetView() {
      this.resetFilters()
      this.resetWorkshop()
      this.forceWorkshop()
      sessionStorage.removeItem('vp_buisness_brand')
      this.$router.push({ name: 'HomeSelection' })
    },
    forceWorkshop() {
      this.currentPage = 'workshop'
      this.$refs.sidebar.setCurrent(0)
    }
  },
  watch: {
    isCurrentParkSaved() {
      Vue.set(this.options[1], 'isDisabled', !this.isCurrentParkSaved || !this.isSampleActive)
    },
    isSampleActive() {
      Vue.set(this.options[1], 'isDisabled', !this.isCurrentParkSaved || !this.isSampleActive)
    },
    workshop() {
      if (!this.isCurrentParkSaved || !this.isSampleActive) {
        this.forceWorkshop()
      }
      this.$refs.sample.fetch()
    },
    currentPage() {
      if (this.currentPage !== 'sample') {
        return
      }
      
      this.$nextTick(() => {
        this.$refs.sample.fetch()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.Home {
  &-header {
    display: flex;
    align-items: center;

    &-menu {
      margin: 0 16px;

      .title {
        margin: 0;
      }
    }

    &-logo {
      flex: 1;
      margin: 8px 0;
      text-align: center;
    }
  }

  &-wrapper {
    margin-top: 8px;
    display: flex;

    &-sidebar {
      width: 120px;
    }
    &-content {
      flex: 1;
      padding: 20px;
    }
  }
}

footer.footer {
  position: fixed;
  height: $bottom-size;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 10;
  background: $background;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 100%;
  }
}
</style>