import { constructChoices, constructFastCheckList, groupFastCheckByCode, getValidPark } from '@/utils/constructFilters'

export default class Age {
  constructor(data, park) {
    if (park === undefined) {
      this._constructFilter(data)
    } else {
      this._constructFilterByPark(data, park)
    }
  }

  _constructFilter(data) {
    const by2Step = data.filter(d => d.data.header.subname === 'by2-STEP')[0]
    const choices = by2Step.data.body.choices
    const categoryList = data.filter(d => d.data.header.subname !== 'by2-STEP')

    this.header = by2Step.data.header
    this.section = this.header.filter
    this.displayStat = true
    this.possiblevalues = {
      choices: constructChoices(choices, 'agetoday_cat', 'interval'),
      fastCheck: constructFastCheckList(categoryList, 'agetoday_cat', 'interval', 'order')
    }
  }

  _constructFilterByPark(filter, park) {
    const choices = park.possiblevalues.filter(value => value.code === 'by2-STEP')
    const fastCheckList = groupFastCheckByCode(park.possiblevalues.filter(value => value.code !== 'by2-STEP'))

    let selectedvalues = []
    let possiblevalues = {}

    possiblevalues.choices = constructChoices(choices, 'agetoday_cat', 'interval')
    possiblevalues.fastCheck = constructFastCheckList(fastCheckList, 'agetoday_cat', 'interval', 'order')
    selectedvalues = possiblevalues.choices.filter(choice => choice.isChecked || choice.isDisabled)

    this.header = filter.header
    this.section = this.header.filter
    this.displayStat = true
    this.possiblevalues = possiblevalues
    this.selectedvalues = selectedvalues

    if (filter.header.version !== park.version) {
      const validPark = getValidPark(filter, park, selectedvalues, possiblevalues)

      this.possiblevalues = validPark.possiblevalues
      this.selectedvalues = validPark.selectedvalues
      this.error = validPark.error
      this.messages = validPark.messages
    }
  }
}
