<template>
  <div class="Check">
    <template v-if="type === 'out'">
      <span v-if="isFirstTopValue">
        <b-icon
          icon="check-square"
          class="is-disabled Check-icon"
        ></b-icon>
      </span>
      <span v-else-if="!!item.isDisabled">
        <b-icon
          icon="times"
          class="is-disabled Check-icon"
        ></b-icon>
      </span>
      <span
        v-else
        @click="updateValue"
      >
        <b-icon
          v-if="!item.isChecked"
          icon="check-square"
          type="is-success"
          class="Check-icon"
        ></b-icon>
        <b-icon
          v-else
          icon="times"
          type="is-danger"
          class="Check-icon"
        ></b-icon>
      </span>
    </template>
    <template v-else-if="type === 'in'">
      <span v-if="!!item.isDisabled">
        <b-icon
          icon="check-square"
          class="is-disabled Check-icon"
        ></b-icon>
      </span>
      <span
        v-else
        @click="updateValue"
      >
        <b-icon
          v-if="item.isChecked"
          icon="check-square"
          type="is-success"
          class="Check-icon"
        ></b-icon>
        <b-icon
          v-else
          icon="times"
          type="is-danger"
          class="Check-icon"
        ></b-icon>
      </span>
    </template>
    <b-checkbox
      v-else-if="type === 'unique'"
      :value="item.isChecked"
      type="is-success"
      @input="updateValue"
    ></b-checkbox>

    <Item class="Check-item" :item="item"/>
  </div>
</template>

<script>
import Vue from 'vue'
import Item from './Item'

export default {
  name: 'ItemCheck',
  components: {
    Item
  },
  props: {
    item: Object,
    choices: Array,
    type: String,
    ordered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icon: 'check'
    }
  },
  computed: {
    isFirstTopValue() {
      return this.item.code === 'TOPxxx' && this.item.top_cat === '[Top150]'
    }
  },
  methods: {
    updateValue() {
      if (this.ordered) {
        // Cas specifique
        return this.checkItemsByOrder()
      }

      if (this.item.isChecked) {
        Vue.delete(this.item, 'isChecked')
      } else {
        Vue.set(this.item, 'isChecked', true)
      }
    },
    checkItemsByOrder() {
      if (this.item.isChecked) {
        this.choices.forEach(choice => {
          if (choice.ktypnr_nb <= this.item.ktypnr_nb && choice.isChecked) {
            Vue.delete(choice, 'isChecked')
          }
        })
      } else {
        this.choices.forEach(choice => {
          if (choice.ktypnr_nb >= this.item.ktypnr_nb) {
            Vue.set(choice, 'isChecked', true)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Check {
  display: flex;

  &-icon {
    margin-top: 3px;
  }

  &-item {
    flex: 1;
  }
}
</style>