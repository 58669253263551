<template>
  <b-button :class="['top-radio', { 'is-active': isActive }]" @click="$emit('input')">
    <b-tooltip 
      placement="top" 
      trigger="hover" 
      :type="top.color" 
      :label="$t('top_represents', { label: top.label, proportion: top.proportion })" 
      :close-delay="0">
      <i class="fas fa-info-circle"></i>
    </b-tooltip>
    <br>
    {{ top.label }}
    <br>
    <b-progress :value="100" :stroke-width="10" :show-value="false" :type="top.color"></b-progress>
  </b-button>
</template>

<script>
export default {
  name: 'SampleFooterButton',
  props: {
    top: Object,
    isActive: Boolean
  }
}
</script>

<style lang="scss">
.top-radio {
  &.button:focus:not(:active) {
    box-shadow: none;
  }

  &.button {
    width: 150px;
    height: 60%;
    line-height: 18px;
    margin-top: 12%;
    border: none;
    background: rgba(136, 197, 238, .2);

    progress.progress, span.b-tooltip {
      display: none;
    }

    &.is-active {
      height: 100%;
      line-height: inherit;
      margin-top: 0;
      background: rgb(136, 197, 238);

      progress.progress {
        display: block;
        width: 60%;
        margin: auto;
      }
      span.b-tooltip {
        display: inline;
      }
    }
  }
  & > span {
    height: 100%;
  }
}
</style>
