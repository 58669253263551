import { http } from './http'

export default {
  getBusinessBrands() {
    return http.get(`/param/buisness_brand`)
  },
  getPresignedDoc(country = 'FRA') {
    return http.get(`/io/vehpark/doc/${country}`)
  }
}
