<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24">
    <template v-if="direction === 'is-up'">
      <path v-if="shape === 'is-empty'" :fill="color" d="M16,13V21H8V13H2L12,3L22,13H16M7,11H10V19H14V11H17L12,6L7,11Z" />
      <path v-if="shape === 'is-full'" :fill="color" d="M16,13V21H8V13H2L12,3L22,13Z" />
      <g v-if="shape === 'is-half'">
        <path :fill="color" d="M16,13V21H8V13H2L12,3L22,13H16M7,11H10V19H14V11H17L12,6L7,11Z" />
        <path :fill="color" d="M12,13V20H9V13H2L12,3Z" />
      </g>
    </template>
    <template v-else>
      <path v-if="shape === 'is-empty'" :fill="color" d="M22,11L12,21L2,11H8V3H16V11H22M12,18L17,13H14V5H10V13H7L12,18Z" />
      <path v-if="shape === 'is-full'" :fill="color" d="M22,11L12,21L2,11H8V3H16V11Z" />
      <g v-if="shape === 'is-half'">
        <path :fill="color" d="M22,11L12,21L2,11H8V3H16V11H22M12,18L17,13H14V5H10V13H7L12,18Z" />
        <path :fill="color" d="M5,12L12,20V4H9V12Z" />
      </g>
    </template>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrow',
  props: {
    width: {
      type: String,
      default: '24px'
    },
    height: {
      type: String,
      default: '24px'
    },
    direction: {
      type: String,
      default: 'is-up',
      validator(value) {
        return [
          'is-up',
          'is-down'
        ].indexOf(value) > -1
      }
    },
    shape: {
      type: String,
      default: 'is-empty',
      validator(value) {
        return [
          'is-empty',
          'is-full',
          'is-half'
        ].indexOf(value) > -1
      }
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>