import api from '@/api/common'

const state = {
  userToken: null,
  businessBrands: []
}

const mutations = {
  SET_TOKEN(state, value) {
    state.userToken = value
    sessionStorage.setItem('vp_token', JSON.stringify(value))
  },
  SET_BUSINESS_BRANDS(state, list) {
    const user = state.userToken || JSON.parse(sessionStorage.getItem('vp_token'))
    state.businessBrands = list.filter(b => user.brands.includes(b.enseigne))
  }
}

const getters = {
  mail: state => state.userToken.decoded.email,
  businessBrands: state => state.businessBrands
}

const actions = {
  setToken({ commit }, userToken) {
    commit('SET_TOKEN', userToken)
  },
  getBusinessBrands({ commit }) {
    api.getBusinessBrands().then(response => {
      commit('SET_BUSINESS_BRANDS', response.data.buisness_brand)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}