import { removeElement } from './helpers'

export default {
  props: {
    message: String,
    duration: Number,
    container: String
  },
  data() {
    return {
      pinPressed: false,
      start: null,
      isActive: false,
      parent: null,
      newContainer: this.container || null
    }
  },
  methods: {
    close() {
      clearTimeout(this.timer)
      this.isActive = false
      this.pinPressed = false

      setTimeout(() => {
        this.$destroy()
        removeElement(this.$el)
      }, 150)
    },

    showNotice() {
      if (this.parent != null && typeof this.parent.insertAdjacentElement === 'function') {
        this.parent.insertAdjacentElement('afterbegin', this.$el)
      }
      this.isActive = true

      if (!this.indefinite) {
        this.start = Date.now()
        this.timer = setTimeout(() => this.close(), this.newDuration)
      }
    },

    addTime(time) {
      if (this.isActive) {
        clearTimeout(this.timer)
        
        time = time - (Date.now() - this.start)
        this.timer = setTimeout(() => this.close(), time)
        this.pinPressed = true
        this.$buefy.toast.open({
          message: 'Épinglé pour 45 secondes',
          type: this.color,
          position: 'is-top-left'
        })
      }
    },

    setupContainer() {
      this.parent = document.querySelector((this.newContainer ? this.newContainer : 'body') + '>.Notifications')

      if (this.parent) return

      if (!this.parent) {
        this.parent = document.createElement('div')
        this.parent.className = 'Notifications'
      }

      const container = document.querySelector(this.newContainer) || document.body

      container.appendChild(this.parent)

      if (this.newContainer) {
        this.parent.classList.add('has-custom-container')
      }
    }
  },
  beforeMount() {
    this.setupContainer()
  },
  mounted() {
    this.showNotice()
  }
}