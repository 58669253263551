<template>
  <div class="Choices">
    <div class="Choices-checklist">
      <span @click="toggleSort" class="Choices-checklist-sort">
        <b-icon icon="sort"></b-icon>
        {{ $t('sort_by') }} {{ sortValues[nextSort] }}
      </span>
      <ItemCheck
        v-for="item in choicesSorted"
        :key="item.name"
        :item="item"
        :choices="choices"
        :type="filterType"
        :ordered="filter.header.type.ordered"
        class="Choices-checklist-item"
      />
    </div>

    <div
      v-if="filter.possiblevalues.fastCheck.length > 0"
      ref="fastChecklist"
      :class="['Choices-fastChecklist', { 'self-end': isBiggerThanParent }]"
    >
      <GroupFastCheck
        v-for="fastCheckList in filter.possiblevalues.fastCheck"
        :key="fastCheckList.code"
        :fastCheckList="fastCheckList"
        :filter="filter"
        :choices="choices"
        :type="filterType"
        @input="initGroup"
        class="Choices-fastChecklist-fastCheck"
        ref="groupFastCheck"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ItemCheck from './ItemCheck'
import GroupFastCheck from './GroupFastCheck'

export default {
  name: 'FiltersModalChoices',
  components: {
    ItemCheck,
    GroupFastCheck,
  },
  props: {
    filter: Object,
    choices: Array,
    parentHeight: Number
  },
  data() {
    return {
      sortBy: 0,
      sortValues: ['origin', 'name', 'immat_volume_ratio'],
      isBiggerThanParent: false
    }
  },
  mounted() {
    this.choices.forEach((choice, index) => {
      choice.origin = index
    })
    
    if (this.choices.length > 0 && this.choices[0].mode === 'interval') {
      // On retire le tri par ordre alpha s'il s'agit d'un mode interval (donc composé de chiffres)
      this.sortValues.splice(this.sortValues.indexOf('name'), 1)
    }

    this.CalculateIsBiggerThanParent()
  },
  computed: {
    choicesSorted() {
      let choices = this.choices
      choices.sort((a, b) => {
        if (a[this.sortLabel] < b[this.sortLabel]) {
          return -1
        } else if (a[this.sortLabel] > b[this.sortLabel]) {
          return 1
        }
        return 0
      })

      return choices
    },
    nextSort() {
      if (this.sortValues.length > this.sortBy + 1) {
        return this.sortBy + 1
      }
      return 0
    },
    sortLabel() {
      return this.sortValues[this.sortBy]
    },
    filterType() {
      return this.filter.header.type.type.toLowerCase()
    }
  },
  methods: {
    CalculateIsBiggerThanParent() {
      Vue.nextTick(() => {
        if (!this.$refs.fastChecklist || !this.parentHeight) {
          this.isBiggerThanParent = false
          return
        }

        this.isBiggerThanParent = this.$refs.fastChecklist.clientHeight > this.parentHeight
      })
    },
    toggleSort() {
      this.sortBy = this.nextSort
    },
    initGroup(item) {
      if (item.isChecked) {
        const groups = this.filter.possiblevalues.fastCheck.filter(fastCheck => fastCheck[0].code != item.code)
        groups.forEach(group => {
          group.forEach(fastCheck => {
            Vue.delete(fastCheck, 'isChecked')
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.Choices {
  display: flex;

  &-checklist {
    flex: 2;
    padding-right: 16px;

    &-item {
      padding: 8px;

      &:nth-child(even) {
        background: $even;
      }
    }

    &-sort {
      display: inline-flex;
      cursor: pointer;
    }
  }

  &-fastChecklist {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    align-self: start;
    flex: 1;
    padding-left: 16px;

    &.self-end {
      top: auto;
      bottom: 0;
      align-self: flex-end;
    }

    &-fastCheck{
      margin-bottom: 24px;
    }
  }
}
</style>