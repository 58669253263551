import { getValidPark } from '@/utils/constructFilters'

export default class Money {
  constructor(data, park) {
    if (park === undefined) {
      this._constructFilter(data)
    } else {
      this._constructFilterByPark(data, park)
    }
  }

  _constructFilter(data) {
    const choicesNTile = data.filter(d => d.data.header.subname === 'VEHESTIMATE-NTILE')[0].data.body.choices
    const choicesCut = data.filter(d => d.data.header.subname === 'VEHESTIMATE-SECTION')[0].data.body.choices

    choicesNTile.map(choice => {
      choice.name = choice.approx_cat
      choice.tooltip = choice.vehestimate_cat
    })
    choicesCut.map(choice => {
      choice.name = choice.approx_cat
      choice.tooltip = choice.vehestimate_cat
    })

    this.header = data[0].data.header
    this.section = this.header.filter
    this.multipleCheck = true
    this.possiblevalues = {
      choices: [choicesNTile, choicesCut],
      fastCheck: []
    }
  }

  _constructFilterByPark(filter, park) {
    const choicesNTile = park.possiblevalues.filter(value => value.code === 'VEHESTIMATE-NTILE')
    const choicesCut = park.possiblevalues.filter(value => value.code === 'VEHESTIMATE-SECTION')
    
    let selectedvalues = []
    let possiblevalues = {}
    let choicesConcat = []
    
    choicesNTile.map(choice => {
      choice.name = choice.approx_cat
      choice.tooltip = choice.vehestimate_cat
    })
    choicesCut.map(choice => {
      choice.name = choice.approx_cat
      choice.tooltip = choice.vehestimate_cat
    })

    possiblevalues.choices = [choicesNTile, choicesCut]
    possiblevalues.fastCheck = []
    possiblevalues.choices.forEach(choice => {
      choicesConcat = choicesConcat.concat(choice.filter(choice => choice.isChecked || choice.isDisabled))
    })
    selectedvalues = choicesConcat

    this.header = filter.header
    this.section = this.header.filter
    this.multipleCheck = true
    this.possiblevalues = possiblevalues
    this.selectedvalues = selectedvalues

    if (filter.header.version !== park.version) {
      const validPark = getValidPark(filter, park, selectedvalues, possiblevalues)

      this.possiblevalues = validPark.possiblevalues
      this.selectedvalues = validPark.selectedvalues
      this.error = validPark.error
      this.messages = validPark.messages
    }
  }
}
