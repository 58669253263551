<template>
  <section v-if="hasSelectedValues || filter.error" class="FiltersAppliedResume">
    <b-message
      v-if="filter.error"
      :title="titleError"
      type="is-danger"
      aria-close-label="Close message"
    >
      <p
        v-for="message in filter.messages"
        :key="message"
      >{{ message }}</p>
    </b-message>

    <template v-if="hasSelectedValues">
      <header class="FiltersAppliedResume-header">
        <p class="FiltersAppliedResume-header-title">
          <b-icon :icon="icon"></b-icon>
          {{ filter.header.name }} -
          <span :class="filterClasses">{{ filterType }}</span>
        </p>

        <a
          v-if="filter.link"
          :href="filter.link"
          target="_blank"
        >{{ $t('vehicles_distribution') }}</a>
        <span
          v-if="filter.displayStat"
          class="FiltersAppliedResume-header-stat"
        >
          {{ $t('stats_based') }} {{ bu }}
        </span>
      </header>

      <div class="FiltersAppliedResume-wrapper">
        <div class="FiltersAppliedResume-wrapper-list">
          <div
            v-for="item in selectedValues"
            :key="item.name"
            class="FiltersAppliedResume-wrapper-list-checked"
          >
            <b-icon
              v-if="filterType === 'out'"
              icon="times"
              type="is-danger"
              class="Check-icon"
            ></b-icon>
            <b-icon
              v-else
              icon="check-square"
              type="is-success"
              class="Check-icon"
            ></b-icon>

            <Item
              :item="item"
              class="FiltersAppliedResume-wrapper-list-checked-item"
            />
          </div>

          <a
            v-if="showRevealButton"
            @click="showAll = !showAll"
          >{{ $t('see_all') }}</a>
          <a
            v-else-if="hideRevealButton"
            @click="showAll = !showAll"
          >{{ $t('see_less') }}</a>

          <FiltersProgress
            v-if="hasProgress"
            class="FiltersAppliedResume-wrapper-list-progress"
            :type="filterType"
            :choices="choices"
            :reverse="reverseProgress"
            :color="progressColor"
          >{{ progressText }}</FiltersProgress>
        </div>

        <div class="FiltersAppliedResume-wrapper-actions">
          <b-button
            class="FiltersAppliedResume-wrapper-actions-button"
            @click="$emit('input', filter)"
          >{{ $t('edit') }}</b-button>
          
          <b-button
            class="FiltersAppliedResume-wrapper-actions-button"
            type="is-danger"
            @click="removeFilter"
          >{{ $t('delete') }}</b-button>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Item from './Item'
import FiltersProgress from './FiltersProgress'
import i18n from '@/lang'

export default {
  name: 'FiltersAppliedResume',
  components: {
    Item,
    FiltersProgress
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          header: {},
          selectedvalues: [],
          possiblevalues: []
        }
      }
    }
  },
  data() {
    return {
      showAll: false
    }
  },
  computed: {
    ...mapGetters('workshop', ['bu']),
    choices() {
      if (this.filter.multipleCheck) {
        return this.filter.possiblevalues.choices.filter(c => c[0].code === this.filter.selectedvalues[0].code)[0]
      }
      return this.filter.possiblevalues.choices
    },
    filterTypeDefault() {
      return this.filter.header.type.type.toLowerCase()
    },
    filterType() {
      let type = this.filterTypeDefault
      if (type === 'unique') {
        return type
      }
      if (this.filter.selectedvalues == null || this.filter.possiblevalues == null) {
        return type
      }
      if (this.filter.selectedvalues.length <= Math.ceil(this.choices.length / 2)) {
        return type
      }
      return type === 'out' ? 'in' : 'out'
    },
    valuesByType() {
      if (this.filterType === this.filterTypeDefault) {
        return this.filter.selectedvalues || []
      }

      const values = []
      this.choices.forEach(choice => {
        if (this.filter.selectedvalues.filter(s => s.name === choice.name).length === 0) {
          values.push(choice)
        }
      })
      return values
    },
    selectedValues() {
      if (this.showAll) {
        return this.valuesByType
      }
      return this.valuesByType.slice(0, 3)
    },
    reverseProgress() {
      return this.filterTypeDefault === 'out'
    },
    showRevealButton() {
      return !this.showAll && this.valuesByType.length > 3
    },
    hideRevealButton() {
      return this.showAll && this.valuesByType.length > 3
    },
    icon() {
      let icon = ''
      switch (this.filter.header.filter) {
        case 'KTYPNR':
          icon = 'car'
          break
        case 'CHANGE_PARK':
          icon = 'database'
          break
        case 'PROPORTION':
          icon = 'percent'
          break
      }
      return icon
    },
    filterClasses() {
      return [
        'type',
        { 'is-out': this.filterType === 'out' },
        { 'is-in': this.filterType === 'in' },
        { 'is-unique': this.filterType === 'unique' }
      ]
    },
    hasSelectedValues() {
      return this.filter.selectedvalues != null && this.filter.selectedvalues.length > 0
    },
    hasProgress() {
      return this.filterType === 'out' || this.filterType === 'in'
    },
    progressText() {
      if (this.filterType === 'out') {
        return i18n.t('data_deleted')
      }
      else if (this.filterType === 'in') {
        return i18n.t('data_added')
      }
      return ''
    },
    progressColor() {
      if (this.filterType === 'out') {
        return 'is-warning'
      }
      return 'is-primary'
    },
    titleError() {
      return this.filter.header != null ? i18n.t('loading_error_filter', { filter: this.filter.header.name }) : i18n.t('loading_error')
    }
  },
  methods: {
    ...mapActions('workshop', ['removeWorkshopFilter']),
    ...mapActions('filter', ['setCountry']),
    removeFilter() {
      this.filter.possiblevalues.choices.forEach(choice => {
        Vue.delete(choice, 'isChecked')
        Vue.delete(choice, 'isDisabled')
      })
      if (this.filter.header.name === 'country') {
        this.setCountry(this.filter.possiblevalues.choices)
      }
      this.removeWorkshopFilter(this.filter.header.name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.FiltersAppliedResume {
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid $secondary;
  }

  &-header {
    display: flex;

    &-title {
      flex: 1;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-stat {
      font-style: italic;
      color: grey;
    }
  }

  &-wrapper {
    display: flex;

    &-list {
      flex: 1;

      &-checked {
        display: flex;
        align-items: center;
        padding: 8px;

        &:nth-child(even) {
          background: $even;
        }

        &-item {
          flex: 1;
        }
      }

      &-progress {
        margin: 16px 0;

        &-bar {
          width: 100%;
        }
      }
    }

    &-actions {
      align-self: flex-start;
      position: sticky;
      top: 20px;
      display: flex;
      flex-direction: column;
      min-width: 180px;
      margin: 0 24px 0 48px;

      &-button {
        margin: 4px 0;
      }
    }
  }
}
</style>