import { constructChoices, constructFastCheckList, groupFastCheckByCode, getValidPark } from '@/utils/constructFilters'

export default class Brand {
  constructor(data, park) {
    if (park === undefined) {
      this._constructFilter(data)
    } else {
      this._constructFilterByPark(data, park)
    }
  }

  _constructFilter(data) {
    const brand = data.filter(d => d.data.header.subname === 'BRAND')[0]
    const choices = brand.data.body.choices
    const categoryList = data.filter(d => d.data.header.subname !== 'BRAND')

    this.header = brand.data.header
    this.section = this.header.filter
    this.possiblevalues = {
      choices: constructChoices(choices, 'brand_cat', 'equals', 'order', true),
      fastCheck: constructFastCheckList(categoryList, 'brand_cat', 'equals', 'order')
    }
  }

  _constructFilterByPark(filter, park) {
    const choices = park.possiblevalues.filter(value => value.code === 'BRAND-ORDER')
    const fastCheckList = groupFastCheckByCode(park.possiblevalues.filter(value => value.code !== 'BRAND-ORDER'))

    let selectedvalues = []
    let possiblevalues = {}

    possiblevalues.choices = constructChoices(choices, 'brand_cat', 'equals', 'order', true)
    possiblevalues.fastCheck = constructFastCheckList(fastCheckList, 'brand_cat', 'equals', 'order')
    selectedvalues = possiblevalues.choices.filter(choice => choice.isChecked || choice.isDisabled)

    this.header = filter.header
    this.section = this.header.filter
    this.possiblevalues = possiblevalues
    this.selectedvalues = selectedvalues

    if (filter.header.version !== park.version) {
      const validPark = getValidPark(filter, park, selectedvalues, possiblevalues)

      this.possiblevalues = validPark.possiblevalues
      this.selectedvalues = validPark.selectedvalues
      this.error = validPark.error
      this.messages = validPark.messages
    }
  }
}
