<template>
  <div class="ItemFastCheck">
    <b-switch
      :value="isChecked"
      type="is-success"
      class="ItemFastCheck-name"
      @input="updateValue"
    >
      {{ item.name }}
    </b-switch>
    <b-progress
      :show-value="false"
      :value="item.immat_volume_ratio"
      type="is-primary"
      class="ItemFastCheck-progress"
    ></b-progress>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ItemFastCheck',
  props: {
    item: Object,
    listItem: Array,
    type: String,
    choices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isChecked() {
      if (this.type === 'out') {
        return !this.item.isChecked
      } else {
        return this.item.isChecked
      }
    },
    fastCheckSelected() {
      let fastCheckSelected = []
      this.listItem.forEach(item => {
        item.forEach(i => {
          if (i.isChecked) {
            fastCheckSelected.push(i)
          }
        })
      })

      return fastCheckSelected
    }
  },
  methods: {
    updateValue() {
      if (this.item.isChecked) {
        Vue.delete(this.item, 'isChecked')
      } else {
        Vue.set(this.item, 'isChecked', true)
      }
      this.$emit('input', this.item)
    }
  },
  watch: {
    'item.isChecked'() {
      this.choices.forEach(choice => {
        Vue.delete(choice, 'isDisabled')

        this.fastCheckSelected.forEach(fastCheck => {
          if (choice.mode === 'equals') {
            choice.parents.forEach(parent => {
              if (fastCheck.children.includes(parent)) {
                Vue.set(choice, 'isDisabled', true)
              }
            })
          } else if (choice.mode === 'interval') {
            if (choice.parents[0] >= fastCheck.children[0] && choice.parents[1] <= fastCheck.children[1]) {
              Vue.set(choice, 'isDisabled', true)
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ItemFastCheck {
  display: flex;
  align-items: center;

  &-name {
    flex: 1;
    max-width: 155px;
    overflow: hidden;
  }

  &-progress {
    flex: 1;
    margin: 0 8px !important;
  }
}
</style>