import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import i18n from './lang'

import GAuth from 'vue-google-oauth2'

Vue.use(require('vue-moment'))

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})
Vue.component('vue-fontawesome', FontAwesomeIcon)

const gauthOption = {
  clientId: "891882987952-t4bt2317kd88tcsarv8tohv0caj5kv5g.apps.googleusercontent.com", 
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
