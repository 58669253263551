import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from 'moment'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

function checkIfUserIsLogged() {
  if (process.env.VUE_APP_TOKEN_REQUIRED != null) {
    try {
      if (checkUserToken()) {
        return
      } else {
        throw new Error("Token expired")
      }
    } catch (e) {
      throw new Error("User not logged")
    }
  } else {
    return
  }
}

function checkUserToken() {
  if (process.env.VUE_APP_TOKEN_REQUIRED != null) {
    try {
      const token = JSON.parse(sessionStorage.getItem('vp_token'))
      const now = Date.now().valueOf() / 1000
      let userTokenMoment = moment(token.decoded.iat * 1000)
      let nowMoment = moment(Date.now())
      if (
        token.decoded.exp > now ||
        (
          nowMoment.dayOfYear() == userTokenMoment.dayOfYear() &&
          nowMoment.year() == userTokenMoment.year()
        )
      ) {
        return true
      }
      return false
    } catch {
      return false
    }
  }
  return true
}

function checkIfBuisnessSelected() {
  const buisnessBrand = sessionStorage.getItem('vp_buisness_brand')

  if (buisnessBrand != null && buisnessBrand != '') {
    return true
  }

  return false
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      try {
        checkIfUserIsLogged()
      } catch (e) {
        console.log(e)
        next('/login')
      }
      if (checkIfBuisnessSelected()) {
        next()
      } else {
        next('/header')
      }
    }
  },
  {
    path: '/header',
    name: 'HomeSelection',
    component: () => import('../views/HomeSelection.vue'),
    beforeEnter: (to, from, next) => {
      try {
        checkIfUserIsLogged()
        next()
      } catch (e) {
        console.log(e)
        next('/login')
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
