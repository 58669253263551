<template>
  <section class="ActionsTooltip">
    <b-tooltip :label="$t('save')">
      <b-button @click="saveWorkshop">
        <b-icon
          icon="download"
          size="is-medium"
        ></b-icon>
      </b-button>
    </b-tooltip>

    <b-tooltip :label="$t('load')">
      <b-button @click="openLoaderPark">
        <b-icon
          icon="upload"
          size="is-medium"
        ></b-icon>
      </b-button>
    </b-tooltip>

    <b-tooltip :label="$t('samples')">
      <b-button @click="showSamples" :disabled="!isCurrentParkSaved">
        <b-icon
          icon="sort-amount-up"
          size="is-medium"
        ></b-icon>
      </b-button>
    </b-tooltip>

    <b-tooltip :label="$t('sample')">
      <b-button @click="openSamples" :disabled="!isCurrentParkSaved">
        <b-icon
          icon="filter"
          size="is-medium"
        ></b-icon>
      </b-button>
    </b-tooltip>

    <b-tooltip :label="$t('export')">
      <b-button @click="exportVehicles" :disabled="!isCurrentParkSaved || !isSampleActive">
        <b-icon
          pack="far"
          icon="file-excel"
          size="is-medium"
        ></b-icon>
      </b-button>
    </b-tooltip>

    <b-modal
      v-model="isCardModalActive"
      :width="modalWidth"
      scroll="keep"
      :can-cancel="false"
    >
      <WorkshopLoadPark
        v-if="modalActive === 'park'"
        :parks="parks"
        :style="`width:${modalWidth}px;`"
        @input="isCardModalActive = false"
      />
      <SampleCreation
        v-else-if="modalActive === 'sample'"
        :style="`width:${modalWidth}px;`"
        :create="isCreating"
        :error-occured="sampleErrorOccured"
        :error-message="sampleErrorMessage"
        @input="isCardModalActive = false"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkshopLoadPark from './WorkshopLoadPark'
import SampleCreation from './SampleCreation'
import i18n from '@/lang'

import api from '@/api/export.js'
import { dataURI_DL } from '@/utils/helpers'

export default {
  name: 'WorkshopActions',
  components: {
    WorkshopLoadPark,
    SampleCreation
  },
  data() {
    return {
      isCardModalActive: false,
      modalActive: '',
      modalWidth: 800,
      sampleErrorMessage: '',
      isCreating: false,
    }
  },
  mounted() {
    this.getParks().catch(() => {
      this.displaySnackbar(i18n.t('error_load_parks'))
    })
  },
  computed: {
    ...mapGetters('workshop', ['workshop', 'bu', 'parks', 'isCurrentParkSaved', 'isSampleActive', 'park']),
    ...mapGetters('sample', ['uuid']),
    sampleErrorOccured() {
      return this.sampleErrorMessage !== ''
    }
  },
  methods: {
    ...mapActions('workshop', ['savePark', 'updatePark', 'getParks', 'createSample', 'getSamples']),
    openLoaderPark() {
      this.modalActive = 'park'
      this.getParks().then(() => {
        this.isCardModalActive = true
      }).catch(() => {
        this.displaySnackbar(i18n.t('error_load_parks'))
      })
    },
    saveWorkshop() {
      if (this.workshop.parkname === '' || this.workshop.heading === '') {
        this.displaySnackbar(i18n.t('invalid_park_header'))
        return
      }
      
      this.savePark().then(() => {
        this.displaySnackbar(i18n.t('park_saved'), false)
        this.getParks()
      }).catch(error => {
        if (error.response != null && error.response.status === 405) {
          // Update du parc
          this.updatePark().then(() => {
            this.displaySnackbar(i18n.t('park_updated'), false)
            this.getParks()
          }).catch(() => {
            this.displaySnackbar(i18n.t('error_update_park'))
          })
        } else {
          this.displaySnackbar(i18n.t('error_save_park'))
        }
      })
    },
    displaySnackbar(message, isError = true) {
      this.$buefy.snackbar.open({
        message: message,
        type: isError ? 'is-danger' : 'is-success',
        position: 'is-top',
      })
    },
    openSamples() {
      this.modalActive = 'sample'
      this.isCardModalActive = true
      this.isCreating = true

      this.createSample().then(() => {
        this.getSamples(this.workshop.idpark)
      }).catch(error => {
        this.sampleErrorMessage = error.response.data
      })
    },
    showSamples() {
      this.modalActive = 'sample'
      this.isCardModalActive = true
      this.isCreating = false
      this.getSamples(this.workshop.idpark)
    },
    exportVehicles() {
      if (this.uuid == null) {
        return false
      }

      this.$buefy.toast.open({
        message: i18n.t('export_vehicles_start'),
        type: 'is-primary',
        queue: false
      })

      api.exportVehicles(this.uuid).then(response => {
        const pre = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"

        dataURI_DL(pre + response.data.file, response.data.fileName)

        this.$buefy.toast.open({
          message: i18n.t('export_vehicles_end'),
          type: 'is-primary',
          queue: false
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: i18n.t('error_export_vehicles'),
          type: 'is-danger',
          queue: false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ActionsTooltip {
  & > .b-tooltip {
    margin-left: 8px;
  }
}
</style>