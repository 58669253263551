import { http } from './http'

const baseUrl = `${http.defaults.baseURL}/io`

export default {
  savePark(data) {
    return http.post(`${baseUrl}/park`, data)
  },
  loadPark(id) {
    return http.get(`${baseUrl}/park/${id}`)
  },
  getParks() {
    return http.get(`${baseUrl}/park`)
  },
  updatePark(data) {
    return http.post(`${baseUrl}/park/update`, data)
  },
  deletePark(id) {
    return http.delete(`${baseUrl}/park/${id}`)
  }
}
