import apiFilter from '@/api/filter'

import ageModel from '@/models/filterAge'
import bodyModel from '@/models/filterBody'
import brandModel from '@/models/filterBrand'
import clusterModel from '@/models/filterCluster'
import countryModel from '@/models/filterCountry'
import cvModel from '@/models/filterCv'
import energyModel from '@/models/filterEnergy'
import moneyModel from '@/models/filterMoney'
import topModel from '@/models/filterTop'
import universeModel from '@/models/filterUniverse'

const state = {
  listBuOnLoad: {},
  listBu: {},
  age: {},
  body: {},
  brand: {},
  cluster: {},
  country: {},
  cv: {},
  energy: {},
  money: {},
  top: {},
  universe: {},
}

const mutations = {
  SET_BU(state, choices) {
    let maxVolume = Math.max.apply(Math, choices.map(choice => choice.immat_volume))
    let primary = choices.filter(choice => choice.immat_volume === maxVolume)[0]

    state.listBu = {
      bu: choices,
      primary: primary,
      isUnique: choices.length === 1
    }
  },
  SET_BU_ON_LOAD(state, listBu) {
    state.listBuOnLoad = listBu
  },
  SET_AGE(state, data) {
    if (checkEnabledState(state.age, data)) {
      state.age = new ageModel(data)
    }
  },
  SET_BODY(state, data) {
    if (checkEnabledState(state.body, data)) {
      state.body = new bodyModel(data)
    }
  },
  SET_BRAND(state, data) {
    if (checkEnabledState(state.brand, data)) {
      state.brand = new brandModel(data)
    }
  },
  SET_CLUSTER(state, data) {
    if (checkEnabledState(state.cluster, data)) {
      state.cluster = new clusterModel(data)
    }
  },
  SET_COUNTRY(state, data) {
    if (checkEnabledState(state.country, data)) {
      state.country = new countryModel(data)
    }
  },
  SET_CV(state, data) {
    if (checkEnabledState(state.cv, data)) {
      state.cv = new cvModel(data)
    }
  },
  SET_ENERGY(state, data) {
    if (checkEnabledState(state.energy, data)) {
      state.energy = new energyModel(data)
    }
  },
  SET_MONEY(state, data) {
    if (checkEnabledState(state.money, data)) {
      state.money = new moneyModel(data)
    }
  },
  SET_TOP(state, data) {
    if (checkEnabledState(state.top, data)) {
      state.top = new topModel(data)
    }
  },
  SET_UNIVERSE(state, data) {
    if (checkEnabledState(state.universe, data)) {
    state.universe = new universeModel(data)
    }
  },
  SET_COUNTRY_BY_PARK(state, data) {
    state.country = new countryModel(state.country, data)
  },
  RESET_FILTERS(state) {
    for (const i in state) {
      state[i] = {}
    }
  }
}

function checkEnabledState(state, data) {
  if (data[0] == null) {
    state.isDisabled = true
    return false
  }
  return true
}

const getters = {
  filters: state => {
    return {
      age: state.age,
      body: state.body,
      brand: state.brand,
      cluster: state.cluster,
      country: state.country,
      cv: state.cv,
      energy: state.energy,
      money: state.money,
      top: state.top,
      universe: state.universe,
    }
  },
  listBuOnLoad: state => state.listBuOnLoad,
  listBu: state => state.listBu,
  bu: state => state.listBu.primary.cd_bu,
  uniqueBu: state => state.listBu.isUnique,
}

const actions = {
  async getFilters({ commit, getters }) {
    const buisnessBrand = sessionStorage.getItem('vp_buisness_brand')
    let countryResponse = await apiFilter.getFilterCountry(buisnessBrand)

    if (countryResponse.data == null || countryResponse.data.length === 0) {
      throw `Erreur de chargement des filtres`
    }

    commit('SET_BU', countryResponse.data[0].data.body.choices)
    commit('SET_BU_ON_LOAD', JSON.parse(JSON.stringify(getters.listBu)))
    commit('workshop/SET_WORKSHOP_BU', JSON.parse(JSON.stringify(getters.listBu)), { root: true })
    
    commit('SET_COUNTRY', countryResponse.data)

    const stuffArray = [
      apiFilter.getFilterAge(getters.bu),
      apiFilter.getFilterBody(),
      apiFilter.getFilterBrand(),
      apiFilter.getFilterCluster(getters.bu),
      apiFilter.getFilterCv(),
      apiFilter.getFilterEnergy(),
      apiFilter.getFilterMoney(),
      apiFilter.getFilterTop(getters.bu),
      apiFilter.getFilterUniverse(getters.bu)
    ]

    await Promise.all(stuffArray).then(results => {
      commit('SET_AGE', results[0].data)
      commit('SET_BODY', results[1].data)
      commit('SET_BRAND', results[2].data)
      commit('SET_CLUSTER', results[3].data)
      commit('SET_CV', results[4].data)
      commit('SET_ENERGY', results[5].data)
      commit('SET_MONEY', results[6].data)
      commit('SET_TOP', results[7].data)
      commit('SET_UNIVERSE', results[8].data)

      commit('workshop/SET_WORKSHOP_FILTERS', { filters: JSON.parse(JSON.stringify(getters.filters)), keep: true }, { root: true })
    })
  },
  async getFiltersByBu({ commit, getters }, { bu, country }) {
    if (country.length > 0) {
      // Si un pays est inclus dans le filtre, il faut gérer le bu en fonction de ça
      // Attention à vérifier que le filtre country retourne bien le même bu primaire que le bu sauvegardé en en-tête
      let choices = []
      country[0].possiblevalues.forEach(possibleValue => {
        if (country[0].selectedvalues.filter(s => s.country_cat === possibleValue.country_cat).length === 0) {
          choices.push(possibleValue)
        }
      })
      
      commit('SET_COUNTRY_BY_PARK', country[0])
      commit('SET_BU', choices)

      if (bu !== getters.bu) {
        // Gestion de l'erreur
        console.error(`Attention, BU en entête différente de la BU primaire du parc`)
        console.error(bu, getters.bu)
      }
    } else {
      // Sinon, il faut considérer que le filtre country n'a pas été utilisé
      // Attention à vérifier que la bu primaire retournée par le filtre est bien la même que la bu sauvegardé en en-tête
      const buisnessBrand = sessionStorage.getItem('vp_buisness_brand')
      let countryResponse = await apiFilter.getFilterCountry(buisnessBrand)

      commit('SET_BU', countryResponse.data[0].data.body.choices)
      commit('SET_BU_ON_LOAD', JSON.parse(JSON.stringify(getters.listBu)))

      commit('SET_COUNTRY', countryResponse.data)

      if (bu !== getters.bu) {
        // Gestion de l'erreur
        console.error(`Attention, BU en entête différente de la BU primaire du filtre`)
        console.error(bu, getters.bu)
      }
    }

    commit('workshop/SET_WORKSHOP_BU', JSON.parse(JSON.stringify(getters.listBu)), { root: true })

    const stuffArray = [
      apiFilter.getFilterAge(getters.bu),
      apiFilter.getFilterBody(),
      apiFilter.getFilterBrand(),
      apiFilter.getFilterCluster(getters.bu),
      apiFilter.getFilterCv(),
      apiFilter.getFilterEnergy(),
      apiFilter.getFilterMoney(),
      apiFilter.getFilterTop(getters.bu),
      apiFilter.getFilterUniverse(getters.bu)
    ]

    await Promise.all(stuffArray).then(results => {
      commit('SET_AGE', results[0].data)
      commit('SET_BODY', results[1].data)
      commit('SET_BRAND', results[2].data)
      commit('SET_CLUSTER', results[3].data)
      commit('SET_CV', results[4].data)
      commit('SET_ENERGY', results[5].data)
      commit('SET_MONEY', results[6].data)
      commit('SET_TOP', results[7].data)
      commit('SET_UNIVERSE', results[8].data)

      commit('workshop/SET_WORKSHOP_FILTERS', { filters: JSON.parse(JSON.stringify(getters.filters)), keep: true }, { root: true })
    })
  },
  async setCountry({ commit, getters }, countries) {
    commit('SET_BU', countries)

    commit('workshop/SET_WORKSHOP_BU', JSON.parse(JSON.stringify(getters.listBu)), { root: true })

    const stuffArray = [
      apiFilter.getFilterAge(getters.bu),
      apiFilter.getFilterCluster(getters.bu),
      apiFilter.getFilterTop(getters.bu),
      apiFilter.getFilterUniverse(getters.bu)
    ]

    await Promise.all(stuffArray).then(results => {
      commit('SET_AGE', results[0].data)
      commit('SET_CLUSTER', results[1].data)
      commit('SET_TOP', results[2].data)
      commit('SET_UNIVERSE', results[3].data)

      commit('workshop/SET_WORKSHOP_FILTERS_BU', JSON.parse(JSON.stringify(getters.filters)), { root: true })
    })
  },
  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}