import { http } from './http'

const numberOfSamples = process.env.VUE_APP_NUMBER_OF_SAMPLES || 1

export default {
  getSample(data) {
    return http.get(`/vehicles`, { params: data })
  },
  getSampleHeaders(id) {
    return http.get(`/io/sample/${id}`, { params: { num: numberOfSamples } })
  },
  createSampleHeader(data) {
    return http.post(`/io/sample`, data)
  }
}
