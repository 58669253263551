import Vue from 'vue'
import VueI18n from 'vue-i18n'
import frLocale from './fr.json'

Vue.use(VueI18n)

const messages = {
  FRA: {
    ...frLocale
  }
}

const defaultLanguage = 'FRA'
const user_info = JSON.parse(sessionStorage.getItem('user_info'))
let locale = defaultLanguage

if (user_info != null) {
  switch (user_info.country) {
    case 'FRA':
      locale = 'FRA';
      break;
    default:
      locale = defaultLanguage;
      break;
  }
}

const i18n = new VueI18n({
  locale,
  messages
})

export default i18n
