<template>
  <section class="FiltersModal modal-card">
    <header class="FiltersModal-header modal-card-head">
      <div class="modal-card-title">
        <p class="FiltersModal-header-title">
          {{ $t('filter') }} - {{ filter.header.name }}
        </p>
        <p class="FiltersModal-header-subtitle">
          {{ $t('filter') }} 
          <span :class="filterClasses">{{ filterType }}</span>
          - {{ $t('type') }} {{ filter.header.filter }}
          <b-icon v-if="icon" :icon="icon"></b-icon>
        </p>
      </div>

      <button
        class="delete"
        aria-label="fermer"
        @click="cancelFilter"
      ></button>
    </header>

    <section class="FiltersModal-body modal-card-body" ref="body">
      <Sidebar
        v-if="filter.multipleCheck"
        :options="options"
        :current="currentCheck"
        class="FiltersModal-body-sidebar"
      />

      <div class="FiltersModal-body-wrapper">
        <div class="FiltersModal-body-wrapper-actions">
          <div class="FiltersModal-body-wrapper-actions-checkbox">
            <b-checkbox
              v-if="displaySelectAll"
              v-model="allChecked"
              :indeterminate="betweenOneAndAll"
              type="is-danger"
              @input="setAllChecked"
            >{{ $t('select_all') }}</b-checkbox>
          </div>

          <a
            v-if="filter.link"
            :href="filter.link"
            target="_blank"
          >{{ $t('vehicles_distribution') }}</a>
          <p
            v-if="filter.displayStat"
            class="FiltersModal-body-wrapper-actions-stat"
          >
            {{ $t('stats_based') }} {{ bu }}
          </p>
        </div>

        <FiltersModalChoices
          :filter="filter"
          :choices="choices"
          :parentHeight="bodyHeight"
        />
      </div>
    </section>

    <footer class="modal-card-foot">
      <button
        class="button is-success"
        @click="applyFilter"
      >{{ $t('valid') }}</button>

      <button
        class="button"
        @click="cancelFilter"
      >{{ $t('cancel') }}</button>

      <FiltersProgress
        v-if="hasProgress"
        class="FiltersModal-progress"
        :type="filterType"
        :choices="choices"
      >{{ progressText }}</FiltersProgress>
    </footer>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import FiltersModalChoices from './FiltersModalChoices'
import FiltersProgress from './FiltersProgress'
import Sidebar from './Sidebar'
import i18n from '@/lang'

export default {
  name: 'FiltersModal',
  components: {
    Sidebar,
    FiltersModalChoices,
    FiltersProgress
  },
  props: {
    filter: Object
  },
  data() {
    return {
      allChecked: false,
      currentCheck: 0,
      bodyHeight: 0,
      previousChoices: [],
      previousFastChecks: [],
      filterClasses: [],
      icon: ''
    }
  },
  beforeMount() {
    if (this.filter.multipleCheck) {
      if (this.filter.possiblevalues.choices[1].filter(choice => choice.isChecked || choice.isDisabled).length > 0) {
        this.currentCheck = 1
      }
      if (this.options[0].isDisabled) {
        this.currentCheck = 1
      }
    }
  },
  mounted() {
    this.bodyHeight = this.$refs.body.clientHeight

    // utilisation du JSON parse stringify pour obtenir une valeur non reactive (necessaire pour l'annulation de la modale)
    // https://stackoverflow.com/questions/53662412/how-can-i-make-a-value-in-vue-js-non-reactive/53662413
    this.previousChoices = JSON.parse(JSON.stringify(this.choices))
    this.previousFastChecks = JSON.parse(JSON.stringify(this.fastChecks))

    this.filterClasses = [
      'type',
      { 'is-out': this.filterType === 'out' },
      { 'is-in': this.filterType === 'in' },
      { 'is-unique': this.filterType === 'unique' }
    ]

    switch (this.filter.section) {
      case 'KTYPNR':
        this.icon = 'car'
        break;
      case 'CHANGE_PARK':
        this.icon = 'database'
        break;
      case 'PROPORTION':
        this.icon = 'percent'
        break;
    }
  },
  computed: {
    ...mapGetters('workshop', ['bu']),
    betweenOneAndAll() {
      let len = this.choices.filter(check => check.isChecked || check.isDisabled).length
      return len > 0 && len < this.choices.length
    },
    hasProgress() {
      return this.filterType === 'out' || this.filterType === 'in'
    },
    progressText() {
      if (this.filterType === 'out') {
        return i18n.t('volume_remain')
      }
      else if (this.filterType === 'in') {
        return i18n.t('volume_added')
      }
      return ''
    },
    choices() {
      if (this.filter.multipleCheck) {
        return this.filter.possiblevalues.choices[this.currentCheck]
      }
      return this.filter.possiblevalues.choices
    },
    fastChecks() {
      return this.filter.possiblevalues.fastCheck
    },
    options() {
      let options = [
        { id: 0, name: 'NTile', icon: 'arrows-alt-h', action: () => this.currentCheck = 0, isDisabled: false },
        { id: 1, name: 'Cut', icon: 'euro-sign', action: () => this.currentCheck = 1, isDisabled: false }
      ]
      if (this.choices.filter(check => check.isChecked || check.isDisabled).length > 0) {
        options.filter(o => o.id !== this.currentCheck)[0].isDisabled = true
      }
      return options
    },
    displaySelectAll() {
      return (this.filterType === 'out' || this.filterType === 'in') && !this.filter.header.type.ordered
    },
    filterType() {
      return this.filter.header.type.type.toLowerCase()
    }
  },
  methods: {
    ...mapActions('workshop', ['setWorkshopFilter']),
    ...mapActions('filter', ['setCountry']),
    applyFilter() {
      let choices = []

      if (this.filterType === 'out' || this.filterType === 'in') {
        choices = this.choices.filter(check => check.isChecked || check.isDisabled)
        if (!this.validationFilterOut(choices)) {
          return
        }
      } else if (this.filterType === 'unique') {
        choices = this.choices.filter(check => check.isChecked)
        if (!this.validationFilterUnique(choices)) {
          return
        }
      }
      
      if (this.filter.setBu) {
        let countries
        if (this.filterType === 'out') {
          countries = this.choices.filter(check => !check.isChecked && !check.isDisabled)
        } else {
          countries = this.choices.filter(check => check.isChecked || check.isDisabled)
        }
        this.setCountry(countries)
      }

      if (this.filter.error) {
        delete this.filter.error
      }

      let filter = {
        ...this.filter,
        selectedvalues: choices,
      }

      this.setWorkshopFilter(filter)
      this.closeModal()
    },
    validationFilterOut(choices) {
      let isValid = true
      let message = ''

      if (this.filter.header.type.maximum === 'ALL-minus1') {
        if (choices.length >= this.choices.length) {
          isValid = false
          message = i18n.t('invalid_allminusone')
        }
      }

      if (choices.length < this.filter.header.type.minimum) {
        isValid = false
        message = i18n.t('invalid_minimum')
      }

      if (!isValid) {
        this.$buefy.snackbar.open({
          message,
          type: 'is-danger',
          position: 'is-top',
        })
      }

      return isValid
    },
    validationFilterUnique(choices) {
      let isValid = true
      let message = ''

      if (choices.length < 1) {
        isValid = false
        message = i18n.t('invalid_unique_minimum')
      }
      if (choices.length > 1) {
        isValid = false
        message = i18n.t('invalid_unique_maximum')
      }

      if (!isValid) {
        this.$buefy.snackbar.open({
          message,
          type: 'is-danger',
          position: 'is-top',
        })
      }

      return isValid
    },
    cancelFilter() {
      this.choices.forEach(choice => {
        this.previousChoices.forEach(prev => {
          if (prev.name === choice.name) {
            Vue.set(choice, 'isChecked', prev.isChecked)
            Vue.set(choice, 'isDisabled', prev.isDisabled)
          }
        })
      })
      this.fastChecks.forEach(fastCheckList => {
        fastCheckList.forEach(fastCheck => {
          this.previousFastChecks.forEach(prevList => {
            prevList.forEach(prev => {
              if (prev.name === fastCheck.name) {
                Vue.set(fastCheck, 'isChecked', prev.isChecked)
              }
            })
          })
        })
      })
      this.closeModal()
    },
    closeModal() {
      this.$emit('input', false)
    },
    setAllChecked() {
      if (this.allChecked) {
        this.choices.forEach(check => {
          Vue.set(check, 'isChecked', true)
        })
      } else {
        // On retire toutes les coches sélectionnées SAUF celles qui sont disabled
        this.choices.forEach(check => {
          if (!check.isDisabled) {
            Vue.set(check, 'isChecked', false)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.FiltersModal {
  &-header {
    text-transform: uppercase;

    &-title {
      font-weight: bold;
    }

    &-subtitle {
      .icon {
        vertical-align: bottom;
      }
    }
  }

  &-body {
    display: flex;

    &-sidebar {
      width: 100px;
    }
    
    &-wrapper {
      flex: 1;

      &-actions {
        display: flex;
        align-items: center;

        &-checkbox {
          flex: 1;
        }

        &-stat {
          margin-bottom: 8px;
          font-style: italic;
          color: grey;
        }
      }
    }
  }

  &-progress {
    width: 50%;
    margin-left: auto;
  }
}
</style>