<template>
  <div id="app" :class="[appVersion]">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  computed: {
    appVersion() {
      return process.env.VUE_APP_IS_STAGING ? 'not-production' : ''
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/init.scss";
@import "@/assets/styles/variables.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.not-production {
  background-color: #fff5eb;
}

.Login {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    padding: 12px;
  }

  &-main {
    padding-top: 36px;

    .select {
      max-width: 300px;
    }
  }
}
.logo {
  max-height: 100px;
}

.is-disabled {
  color: #cccccc;
}

.monofont .tooltip-content {
  font-family: "Lucida Console", Monaco, monospace;
}

.FilterSection {
  &-title {
    font-weight: bold;
    text-transform: uppercase;
  }
  &-divider {
    background: $secondary;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.notices .snackbar.is-danger {
  background-color: $danger;
}
.notices .snackbar.is-danger .action.is-danger .button {
  color: white;
  background-color: $danger;
}

.type {
  font-weight: bold;

  &.is-out {
    color: $danger;
  }
  &.is-in {
    color: $success;
  }
  &.is-unique {
    color: $primary;
  }
}

p {
  &.is-danger {
    color: $danger;
  }
}
</style>
