<template>
  <aside class="SideBar">
    <b-button 
      v-for="(option, index) in options" 
      :key="option.name" 
      :class="['SideBar-button', { 'is-disabled': option.isDisabled, 'is-current': currentOption === index }]"
      @click="callAction(index, option)"
    >
      <b-icon
        :icon="option.icon"
        class="icon"
      ></b-icon>
    </b-button>
  </aside>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'SideBar',
  props: {
    options: Array,
    current: {
      Type: Number,
      default: -1
    }
  },
  data() {
    return {
      currentOption: 0
    }
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.current !== -1) {
        this.currentOption = this.current
      }
    })
  },
  methods: {
    callAction(index, option) {
      if (option.isDisabled) {
        return
      }

      this.currentOption = index
      return option.action()
    },
    setCurrent(index) {
      this.currentOption = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.SideBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 70vh;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    top: 0;
    bottom: 0;
    width: 2px;
    background: $secondary;
  }

  &-button {
    display: flex;
    width: 80px;
    height: 80px;
    margin: 10px auto;
    font-size: 48px;
    padding: 0.1em;

    &.is-current {
      background: $primary;

      .icon {
        color: white;
      }
    }
    &.is-disabled {
      background: $background;
      cursor: not-allowed;

      .icon {
        opacity: 0.5;
      }
    }

    &:first-child {
      margin-top: 20px;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/variables.scss";

.SideBar-button {
  &.button:focus:not(:active), &.button.is-focused:not(:active) {
    box-shadow: none;
    border-color: $secondary;

    &.is-current {
      border-color: $primary;
    }
  }
  &.button:active, &.button.is-active, &.button:hover, &.button.is-hovered {
    border-color: $secondary;
  }
}
</style>